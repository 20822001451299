import { getDashboardPatientsStats } from "http-calls";
import moment from "moment";
import React, { Component } from "react";
import Loader from "react-loader-spinner";
import PatientsColumnChart from "../patients-column-chart/patients-column-chart";
import PatientsVisitDetailsTable from "../patients-visit-details-table/patients-visit-details-table";
import "./detailed-stats.scss";
import CsvDownloader from "react-csv-downloader";
import PatientsCSVDownloader from "../patients-csv-downloader/patients-csv-downloader";

class DetailedStats extends Component {
  state = {
    chartData: [],
    tableData: [],
    isLoading: false,
  };

  componentDidMount() {
    this._fetchStats();
  }

  _toggleLoader = (isLoading) => {
    this.setState({ isLoading });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.dateRange) !==
      JSON.stringify(this.props.dateRange)
    ) {
      this._fetchStats();
    }
  }

  _fetchStats = async () => {
    try {
      this._toggleLoader(true);
      const { dateRange } = this.props;
      const StartDate = moment(dateRange[0]).format("MM/DD/YYYY");
      const EndDate = moment(dateRange[1]).format("MM/DD/YYYY");
      const ClinicID =
        this.props.userData && this.props.userData.selectedClinic
          ? this.props.userData.selectedClinic.ClinicID
          : 1;
      const response = await getDashboardPatientsStats({
        StartDate,
        EndDate,
        ClinicID,
      });
      // Prepare data for chart view
      const allPatientsLogs = JSON.parse(response);
      const chartData = this._prepareChartData(allPatientsLogs);
      // Prepare data for table view
      const tableData = this._prepareTableData(allPatientsLogs);
      this.setState({ chartData, tableData });
      this._toggleLoader(false);
    } catch (error) {
      console.log("error :>> ", error);
      this.setState({ chartData: [], tableData: [] });
      this._toggleLoader(false);
    }
  };

  _prepareChartData = (patientsLogs) => {
    // Group patients
    const patients = {};
    patientsLogs.forEach((patientLog) => {
      if (!patients[patientLog.PatientID]) {
        patients[patientLog.PatientID] = {
          name: `${patientLog.Firstname} ${patientLog.LastName}`,
          times: {},
        };
      }
      patients[patientLog.PatientID].times[patientLog.Status] = +new Date(
        patientLog.UpdateDate
      );
    });
    // Calculate total lab time
    for (let patientId in patients) {
      patients[patientId].totalLabTime = this._calculateTotalLabTime(
        patients[patientId].times
      );
    }
    // Prepare chart format
    const chartData = [];
    for (let patientId in patients) {
      chartData.push([
        patients[patientId].name,
        patients[patientId].totalLabTime,
      ]);
    }
    return chartData;
  };

  _calculateTotalLabTime = (patientTimes) => {
    const firstPhase = this._determineFirstPhase(patientTimes);
    const lastPhase = this._determineLastPhase(patientTimes);
    const firstPhaseTime = patientTimes[firstPhase];
    const lastPhaseTime = patientTimes[lastPhase];
    const time = this._toFixedIfNecessary(
      (lastPhaseTime - firstPhaseTime) / (1000 * 60)
    );
    if (time > 300) {
      return 0;
    } else {
      return time;
    }
  };

  _prepareTableData = (allPatientsLogs) => {
    console.log("allPatientsLogs :>> ", allPatientsLogs);
    // Group patients
    const patients = {};
    allPatientsLogs.forEach((patientLog) => {
      if (!patients[patientLog.PatientID]) {
        patients[patientLog.PatientID] = {
          ...patientLog,
          stages: {},
        };
      }
      patients[patientLog.PatientID].stages[patientLog.Status] = {
        updatedBy: patientLog.UpdatedBy,
        updatedAt: patientLog.UpdateDate,
      };
    });
    // Calculate and prepare table data
    const tableData = [];
    for (let patientId in patients) {
      const tableRow = {
        registeredBy:
          patients[patientId].stages["Registered"] &&
          patients[patientId].stages["Registered"].updatedBy
            ? patients[patientId].stages["Registered"].updatedBy
            : "--",
        calledBy:
          patients[patientId].stages["Called"] &&
          patients[patientId].stages["Called"].updatedBy
            ? patients[patientId].stages["Called"].updatedBy
            : "--",
        patientId: patientId,
        firstName: patients[patientId].Firstname,
        lastName: patients[patientId].LastName,
        times: {},
        patientData: patients[patientId],
      };
      const { stages } = patients[patientId];
      tableRow.times.signInToRegister = this._calculateTimeDifference(
        {
          from: "SignedIn",
          to: "Registered",
        },
        stages
      );
      tableRow.times.registerToOrderActivation = this._calculateTimeDifference(
        {
          from: "Registered",
          to: "Ready For Order Activation",
        },
        stages
      );
      tableRow.times.orderActivationToPendingCollection = this._calculateTimeDifference(
        {
          from: "Ready For Order Activation",
          to: "Pending Collection",
        },
        stages
      );
      tableRow.times.pendingCollectionToCalled = this._calculateTimeDifference(
        {
          from: "Pending Collection",
          to: "Called",
        },
        stages
      );
      tableRow.times.calledToCompleted = this._calculateTimeDifference(
        {
          from: "Called",
          to: "Completed",
        },
        stages
      );
      tableRow.times.totalCompletion = this._calculateTimeDifference(
        {
          from: "SignedIn",
          to: "Completed",
        },
        stages
      );
      tableRow.times.totalLabTime = this._calculateTimeDifference(
        {
          from: "Registered",
          to: "Completed",
        },
        stages
      );
      tableRow.times.noAnswer = "--";
      tableRow.times.orderIssueTime = "--";
      tableData.push(tableRow);
    }
    return tableData;
  };

  _toFixedIfNecessary = (value, dp = 2) => {
    return +parseFloat(Math.abs(value)).toFixed(dp);
  };

  _calculateTimeDifference = (stages, allStates) => {
    const { from, to } = stages;
    if (
      allStates[from] &&
      Object.keys(allStates[from]).length &&
      allStates[to] &&
      Object.keys(allStates[to]).length
    ) {
      return this._toFixedIfNecessary(
        (parseInt(+new Date(allStates[to].updatedAt)) -
          parseInt(+new Date(allStates[from].updatedAt))) /
          (1000 * 60)
      );
    } else {
      return "--";
    }
  };

  _determineLastPhase = (patientTimes) => {
    const phasesInDescendingOrder = [
      "SignedIn",
      "Registered",
      "Ready For Order Activation",
      "Pending Collection",
      "Called",
      "Completed",
      "Order Issues",
      "No Answer",
    ];
    for (let phase of phasesInDescendingOrder.reverse()) {
      if (patientTimes[phase] && patientTimes[phase] > 0) {
        return phase;
      }
    }
  };

  _determineFirstPhase = (patientTimes) => {
    const phasesInDescendingOrder = [
      "SignedIn",
      "Registered",
      "Ready For Order Activation",
      "Pending Collection",
      "Called",
      "Completed",
      "Order Issues",
      "No Answer",
    ];
    for (let phase of phasesInDescendingOrder) {
      if (patientTimes[phase] && patientTimes[phase] > 0) {
        return phase;
      }
    }
  };

  render() {
    const { chartData, tableData, isLoading } = this.state;
    const { isActive } = this.props;

    const columns = [
      {
        id: "first",
        displayName: "First column",
      },
      {
        id: "second",
        displayName: "Second column",
      },
    ];

    const datas = [
      {
        first: "foo",
        second: "bar",
      },
      {
        first: "foobar",
        second: "foobar",
      },
    ];

    if (isActive) {
      if (isLoading) {
        return (
          <div className="dashboardLoaderWrapper">
            <Loader type="ThreeDots" color="black" height={50} width={50} />
          </div>
        );
      } else {
        return (
          <>
            <div className="sectionHeader">
              Total Completion by First Name & Last Name
            </div>
            <PatientsColumnChart chartData={chartData} />
            <div className="csvDownloadPromptBtnWrapper">
              <PatientsCSVDownloader data={tableData}>
                <button className="csvDownloadPromptBtn">
                  <i className="fa fa-download" aria-hidden="true"></i>
                  Download CSV
                </button>
              </PatientsCSVDownloader>
            </div>
            <div className="sectionHeader tableHeader">
              Patient Visit Process
            </div>
            <PatientsVisitDetailsTable tableData={tableData} />
          </>
        );
      }
    } else {
      return <></>;
    }
  }
}

export default DetailedStats;
