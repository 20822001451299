import React, { Component } from "react";
import "./dashboard-page.scss";
import DateRangePicker from "modules/dashboard-module/components/date-range-picker/date-range-picker";
import LineChart from "modules/dashboard-module/components/line-chart/line-chart";
import SidebarFilter from "modules/dashboard-module/components/sidebar-filter/sidebar-filter";
import DashboardStat from "modules/dashboard-module/components/dashboard-stat/dashboard-stat";
import { getAvgWaitTime, getDailyVisits } from "http-calls";
import moment from "moment";
import { connect } from "react-redux";
import { showLoader } from "redux/actions/loader-data";
import { hideLoader } from "redux/actions/loader-data";
import { showToast } from "helper-methods";
import SummarizedStats from "modules/dashboard-module/components/summarized-stats/summarized-stats";
import DetailedStats from "modules/dashboard-module/components/detailed-stats/detailed-stats";

class DashboardPage extends Component {
  state = {
    values: [],
    isLoaded: false,
    dateRange: [],
    activeTabIndex: 0, // Allowed values 0 (Tab 1) and 1 (Tab 2)
  };

  componentDidMount() {
    this.props.hideLoader();
  }

  _onDateRangeUpdate = (dateRange) => {
    this.setState({ dateRange });
  };

  _updateActiveTab = (activeTabIndex) => {
    this.setState({ activeTabIndex });
  };

  _getActiveTabClass = tabIndex => {
    const { activeTabIndex } = this.state;
    let tabClasses = "tab";
    if (activeTabIndex === tabIndex) {
      tabClasses += " active";
    }
    return tabClasses;
  }

  render() {
    const { activeTabIndex, dateRange } = this.state;

    return (
      <>
        <div className="dashboardPageWrapper">
          {/* <div className="dashboardSidebarWarpper">
            <SidebarFilter headerLabel={"Registered By"} />
            <SidebarFilter headerLabel={"Called By"} />
            <SidebarFilter headerLabel={"Reason for visit"} />
          </div> */}
          <div className="mainArea">
            <div className="actionBar">
              <DateRangePicker onChange={this._onDateRangeUpdate} />
            </div>
            {dateRange && dateRange.length === 2 ? (
              <div className="dashboardTabWrapper">
                <div className="tabsSelectorWrapper">
                  <div className="tabsSelector">
                    <div onClick={e => this._updateActiveTab(0)} className={this._getActiveTabClass(0)} >Summarized Stats</div>
                    <div onClick={e => this._updateActiveTab(1)} className={this._getActiveTabClass(1)}>Detailed Stats</div>
                  </div>
                </div>
                <SummarizedStats
                  dateRange={dateRange}
                  isActive={activeTabIndex === 0}
                />
                <DetailedStats
                  dateRange={dateRange}
                  isActive={activeTabIndex === 1}
                />
              </div>
            ) : (
              <div className="initialLabel">Please select a date range</div>
            )}
          </div>
        </div>
        {/* 
        <LineChart /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
