import * as _deepClone from "clone-deep";
import cogoToast from "cogo-toast";
import { clearUserData } from "../redux/actions/user-data";
import { store } from "../redux/store";

export const logout = (navRef) => {
  store.dispatch(clearUserData());
  // Clear other reducers data also
  if (navRef) {
    navRef.replace("/login");
  }
};

export const deepClone = (data) => {
  return _deepClone(data);
};

export const sleepTime = (n) => new Promise((r) => setTimeout(() => r(), n));

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const showToast = (
  message,
  type = "error",
  options = {
    position: "top-center",
  }
) => {
  cogoToast[type](message, options);
};

export const navigateToRoute = (
  navRef,
  route,
  method = "push",
  clickEvent = null
) => {
  if (clickEvent) {
    clickEvent.preventDefault();
  }
  if (navRef) {
    navRef[method](route);
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const doesArraysContainSameSetOfValues = (_arr1, _arr2) => {
  if (
    !Array.isArray(_arr1) ||
    !Array.isArray(_arr2) ||
    _arr1.length !== _arr2.length
  )
    return false;

  let arr1 = _arr1.concat().sort();
  let arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};

export const extractQueryParams = () => {
  let {
    location: { search: queryParamString },
  } = window;
  let params = {};
  if (queryParamString.length > 1 && queryParamString.indexOf("?") > -1) {
    queryParamString = queryParamString.replace("?", "");
    if (queryParamString.indexOf("&") === -1) {
      // Contains only one param
      const paramParts = queryParamString.split("=");
      params[paramParts[0]] = paramParts[1];
    } else {
      // Contains multiple params
      const queryParams = queryParamString.split("&");
      queryParams.forEach((queryParam) => {
        const paramParts = queryParam.split("=");
        params[paramParts[0]] = paramParts[1];
      });
    }
  }
  return params;
};

export const extractNames = (fullName) => {
  const nameParts = fullName.split(" ");
  const parts = {
    firstName: "",
    lastName: "",
  };
  if (nameParts.length) {
    if (nameParts[0].length) {
      parts.firstName = nameParts[0];
    }
    if (nameParts.length === 2 && nameParts[1].length) {
      parts.lastName = nameParts[1];
    } else if (nameParts.length > 2) {
      for (let i = 1; i < nameParts.length; i++) {
        if (i > 1) {
          parts.lastName += " ";
        }
        parts.lastName += nameParts[i];
      }
    }
  }
  return parts;
};
