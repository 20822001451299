import { extractQueryParams } from "helper-methods";
import { getAvgWaitTime, getPatientStatus } from "http-calls";
import moment from "moment";
import React, { Component } from "react";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";
import "./patient-status-page.scss";

let intervalRef = null;

const FLOW = [
  { stateId: "SignedIn", stepType: false, StatusID: 1 },
  { stateId: "Registered", errorStep: false, StatusID: 2 },
  { stateId: "Ready For Order Activation", errorStep: false, StatusID: 3 },
  { stateId: "Pending Collection", errorStep: false, StatusID: 4 },
  { stateId: "Called", errorStep: false, StatusID: 5 },
  { stateId: "Completed", errorStep: false, isCompleteStep: true, StatusID: 6 },
];

const ERROR_STEPS = ["Order Issues", "No Answer", "Lab Order Issues"];

class PatientStatusPage extends Component {
  state = {
    callNumber: "",
    date: "",
    steps: [],
    currentState: "pending",
    isLoading: false,
    esimatedWaitTime: null,
  };

  componentDidMount() {
    this._fetchPatientDetails();
  }

  _fetchPatientDetails = async () => {
    try {
      this._toggleLoader(true);
      await this._loadData();
      this._toggleLoader(false);
      this._initializeBackgroundRefresher();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "No data found!",
        text: "We are unable to process your request now. Please check later",
        onClose: () => {
          var myWindow = window.open("", "_self");
          myWindow.document.write("");
          setTimeout(function () {
            myWindow.close();
          }, 1000);
        },
      });
    }
  };

  _initializeBackgroundRefresher = () => {
    intervalRef = setInterval(async () => {
      // Check if refresh required or not
      if (this._isRefreshRequired()) {
        try {
          await this._loadData();
        } catch (error) {
          clearInterval(intervalRef);
          Swal.fire({
            icon: "error",
            title: "No data found!",
            text:
              "We are unable to process your request now. Please check later",
            onClose: () => {
              var myWindow = window.open("", "_self");
              myWindow.document.write("");
              setTimeout(function () {
                myWindow.close();
              }, 1000);
            },
          });
        }
      } else {
        clearInterval(intervalRef);
      }
    }, 10000);
  };

  _loadData = () => {
    return new Promise(async (resolve, reject) => {
      const parameters = extractQueryParams();
      if (parameters && parameters.id) {
        const response = await getPatientStatus(parameters.id);
        const stages = JSON.parse(response);
        const callNumber = this._extractCallNumber(stages);
        const date = this._extractDate(stages);
        const steps = this._extractSteps(stages);
        const clinicId = this._extractClinicId(stages);
        const esimatedWaitTime = this._extractEstimatedWaitTime(
          stages,
          clinicId
        );
        this.setState(
          { callNumber, date, steps, esimatedWaitTime, clinicId },
          () => {
            resolve();
          }
        );
      } else {
        throw new Error();
      }
    });
  };

  _isRefreshRequired = () => {
    const { steps } = this.state;
    const latestStep = steps.find((s) => s.isCurrent);
    if (latestStep.name !== "Completed") {
      return true;
    }
    return false;
  };

  _extractEstimatedWaitTime = async (stages, clinicId) => {
    const lastStage = stages[stages.length - 1];
    const lastStageIndexInFlow = FLOW.findIndex(
      (s) => s.stateId === lastStage.Status
    );
    let waitTime = null;
    if (lastStageIndexInFlow > -1 && lastStageIndexInFlow < 5) {
      const date = moment().format("MM/DD/YYYY");
      const dateResponse = await getAvgWaitTime({
        StartDate: date, //MM/DD/YYYY
        EndDate: date, //MM/DD/YYYY
        StartStage: FLOW[lastStageIndexInFlow].StatusID, //StageID
        EndState: "5", //stageID
        ClinicID: clinicId,
      });
      if (dateResponse && dateResponse.AvgTime) {
        waitTime = this._transformToMinutesAndHours(dateResponse.AvgTime);
      }
    }
    return waitTime;
  };

  _transformToMinutesAndHours = (timeInSeconds) => {
    const d = Number(timeInSeconds);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    return hDisplay + mDisplay;
  };

  _toggleLoader = (isLoading) => {
    this.setState({ isLoading });
  };

  _extractCallNumber = (stages) => {
    return stages[0].Callnumber;
  };

  _extractClinicId = (stages) => {
    return stages[0].ClinicID;
  };

  _extractDate = (stages) => {
    return moment(stages[0].UpdateDate).format("Do MMMM YYYY");
  };

  _extractSteps = (stages) => {
    // Remove duplicate entries
    let formattedStages = this._removeDuplicateStages(stages);
    formattedStages = this._removeUnorderedStages(formattedStages);
    // Remove internal error entries
    formattedStages = this._removeInternalErrorStages(formattedStages);
    // Add all stages
    let timelineSteps = [];
    formattedStages.forEach((stage, stageIndex) => {
      if (stageIndex === formattedStages.length - 1) {
        // Last stage
        // Check if it's an error stage or not
        if (ERROR_STEPS.indexOf(stage.Status) > -1) {
          // Append it to previous stage
          timelineSteps[stageIndex - 1].hasError = true;
          timelineSteps[stageIndex - 1].errorText = stage.Status;
          timelineSteps[stageIndex - 1].errorDateTime = stage.UpdateDate;
          timelineSteps[stageIndex - 1].isCurrent = true;
          return;
        }
      }
      timelineSteps.push({
        name: stage.Status,
        dateTime: stage.UpdateDate,
        hasError: false,
        isAttempted: true,
        isCurrent: stageIndex === formattedStages.length - 1,
      });
    });
    // Append remaining stages from the flow
    const remainingStages = this._generateRemainingStages(timelineSteps);
    timelineSteps = [...timelineSteps, ...remainingStages];
    return timelineSteps;
  };

  _removeUnorderedStages = (formattedStages) => {
    let latestStage = formattedStages[formattedStages.length - 1];
    let latestStageIndexInFlow = FLOW.findIndex(
      (s) => s.stateId === latestStage.Status
    );
    if (latestStageIndexInFlow === -1) {
      // Error step
      // So get the previous one
      latestStage = formattedStages[formattedStages.length - 2];
      latestStageIndexInFlow = FLOW.findIndex(
        (s) => s.stateId === latestStage.Status
      );
    }
    const visibleStages = formattedStages.filter((stage) => {
      const currentStageIndexInFlow = FLOW.findIndex(
        (s) => s.stateId === stage.Status
      );
      if (currentStageIndexInFlow <= latestStageIndexInFlow) {
        return true;
      }
    });
    return visibleStages;
  };

  _generateRemainingStages = (timelineSteps) => {
    // Get last step of timeline
    const lastStepOfTimeline = timelineSteps[timelineSteps.length - 1];
    // Find index of last time line step in the flow
    const lastTimelineStepIndexInFlow = FLOW.findIndex(
      (s) => s.stateId === lastStepOfTimeline.name
    );
    const remainingSteps = [];
    for (let i = lastTimelineStepIndexInFlow + 1; i < FLOW.length; i++) {
      remainingSteps.push({
        name: FLOW[i].stateId,
        dateTime: null,
        hasError: false,
        isAttempted: false,
        isCurrent: false,
      });
    }
    return remainingSteps;
  };

  _removeInternalErrorStages = (stages) => {
    // Remove all the error steps before the latest one
    const formattedStages = stages.filter((stage, stageIndex) => {
      if (stageIndex === stages.length - 1) {
        return true;
      } else {
        // Not last stage
        if (ERROR_STEPS.indexOf(stage.Status) === -1) {
          // Not an internal error step
          return true;
        }
      }
    });
    return formattedStages;
  };

  _removeDuplicateStages = (stages) => {
    const idMappedStages = {};
    stages.forEach((stage) => {
      idMappedStages[stage.Status] = stage;
    });
    // Convert it back to array
    let formattedStages = Object.values(idMappedStages);
    // Sort
    formattedStages = formattedStages.sort((s1, s2) => {
      return moment.utc(s1.UpdateDate).diff(moment.utc(s2.UpdateDate));
    });
    return formattedStages;
  };

  _generateStepClassnames = (step) => {
    let classNames = "";
    if (step.isAttempted && !step.hasError) {
      classNames = "success";
    } else if (step.isAttempted && step.hasError) {
      classNames = "error";
    }
    if (step.isCurrent) {
      classNames += " current";
    }
    return classNames;
  };

  render() {
    const { callNumber, date, steps, isLoading, waitTime } = this.state;
    const isTodaysCheckin = moment().format("Do MMMM YYYY") === date;
    return (
      <>
        <div className="patientStatusPageWrapper">
          <div className="pageBody">
            <div className="leftPart">
              <h3 className="labHeaderLabel">Lab Checkin</h3>
              {!isLoading && (
                <div className="patientDetailsWrapper">
                  {/* <div className="avatarWrapper"></div> */}
                  <p className="patientName">
                    Call Number: <strong>{callNumber}</strong>
                  </p>
                  <p className="callNumber">
                    Date: <strong>{date}</strong>
                  </p>
                  {waitTime && (
                    <p className="callNumber">
                      <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                      &nbsp;Estimated Waittime: <strong>{waitTime}</strong>
                    </p>
                  )}
                </div>
              )}
            </div>
            <div className="rightPart">
              {isLoading ? (
                <div className="loaderWrapper">
                  <Loader type="Puff" color="#212121" height={50} width={50} />
                  <p className="loaderText">Please wait</p>
                </div>
              ) : (
                <>
                  {isTodaysCheckin ? (
                    <div class="outer">
                      <div className="outer">
                        <div className="progress">
                          <div className="left">
                            {steps.map((step, stepIndex) => (
                              <div key={stepIndex}>
                                {stepIndex === 0 && "Start"}
                                {stepIndex === steps.length - 1 && "End"}
                              </div>
                            ))}
                          </div>
                          <div className="right">
                            {steps.map((step, stepIndex) => (
                              <div
                                key={stepIndex}
                                className={this._generateStepClassnames(step)}
                              >
                                {step.name}
                                {step.isAttempted && (
                                  <p className="stepTime">
                                    <i
                                      className="fa fa-clock-o"
                                      aria-hidden="true"
                                    ></i>
                                    {moment(step.dateTime).format("h:mm a")}
                                  </p>
                                )}
                                {step.hasError && (
                                  <h4 className="errorText">
                                    <i
                                      class="fa fa-exclamation-triangle"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    &nbsp;
                                    {step.errorText}
                                  </h4>
                                )}
                                {/* <p className="stepTime">10:10 am</p> */}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="visitCompletedMessgae">
                      <p>Your visit has been completed</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PatientStatusPage;
