import React, { Component } from "react";
import "./sidebar-filter.scss";

class SidebarFilter extends Component {
  state = {};
  render() {
    const { headerLabel, options } = this.props;
    
    return (
      <div className="dashboardFilterWrapper">
        <div className="filterHeader">{headerLabel}</div>
        <div className="filterBody">
          <div className="filterOptionWRapper">
            <input type="checkbox" /> John Doe
          </div>
          <div className="filterOptionWRapper">
            <input type="checkbox" /> John Doe
          </div>
          <div className="filterOptionWRapper">
            <input type="checkbox" /> John Doe
          </div>
          <div className="filterOptionWRapper">
            <input type="checkbox" /> John Doe
          </div>
          <div className="filterOptionWRapper">
            <input type="checkbox" /> John Doe
          </div>
          <div className="filterOptionWRapper">
            <input type="checkbox" /> John Doe
          </div>
          <div className="filterOptionWRapper">
            <input type="checkbox" /> John Doe
          </div>
          <div className="filterOptionWRapper">
            <input type="checkbox" /> John Doe
          </div>
          <div className="filterOptionWRapper">
            <input type="checkbox" /> John Doe
          </div>
          <div className="filterOptionWRapper">
            <input type="checkbox" /> John Doe
          </div>
          <div className="filterOptionWRapper">
            <input type="checkbox" /> John Doe
          </div>

          <div className="filterOptionWRapper">
            <input type="checkbox" /> John Doe
          </div>
        </div>
      </div>
    );
  }
}

export default SidebarFilter;
