import React, { Component } from "react";
import { getPatientDetailsById } from "http-calls";
import Loader from "react-loader-spinner";
import moment from "moment";

class QuickSearchVisitLogs extends Component {
  state = {
    isLoading: false,
    logs: [],
    error: {
      isVisible: false,
      errorText: "No Data Found",
    },
  };

  _toggleLoader = (isLoading) => {
    this.setState({ isLoading });
  };

  _showError = (errorText = "No Data Found") => {
    this.setState({
      error: {
        isVisible: true,
        errorText,
      },
    });
  };

  _hideError = () => {
    this.setState({
      error: {
        isVisible: false,
      },
    });
  };

  componentDidMount() {
    this._loadVisitDetails();
  }

  _loadVisitDetails = async () => {
    this._hideError();
    this._toggleLoader(true);
    try {
      const logs = await getPatientDetailsById(
        this.props.patient.patientVisitId
      );
      this.setState({ logs });
      this._toggleLoader(false);
    } catch (error) {
      this._toggleLoader(false);
      this._showError();
    }
  };

  render() {
    const { onBack, patient } = this.props;
    const { isLoading, logs, error } = this.state;

    return (
      <div className="patientView">
        <div className="backBar">
          <div className="backBtn" onClick={onBack}>
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            <div className="text">Back to results</div>
          </div>
        </div>
        <div className="visitHistoryHeader">
          <h3>Patient Visit Log</h3>
          <h3>Name: {patient.name}</h3>
        </div>
        {isLoading ? (
          <div className="loaderWrapper">
            <Loader type="ThreeDots" color="black" height={50} width={50} />
          </div>
        ) : (
          <>
            {error.isVisible ? (
              <div className="noResultsWrapper">{error.errorText}</div>
            ) : (
              <>
                {logs ? (
                  <div className="visitHistoryLogs">
                    <div>
                      <div className="tbl-header">
                        <table cellPadding={0} cellSpacing={0} border={0}>
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Attended by</th>
                              <th>Updated at</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="tbl-content">
                        <table cellPadding={0} cellSpacing={0} border={0}>
                          <tbody>
                            {logs.map((log, logIndex) => (
                              <tr key={logIndex}>
                                <td>{log.Status}</td>
                                <td>
                                  {" "}
                                  by <strong>{log.UpdatedBy}</strong>
                                </td>
                                <td>
                                  {" "}
                                  at{" "}
                                  <strong>
                                    {moment(log.UpdateDate).format(
                                      "MM/DD/YYYY"
                                            )} - {moment(log.UpdateDate).format('LTS')}
                                  </strong>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}{" "}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

export default QuickSearchVisitLogs;
