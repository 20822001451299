import AccessTime from "@material-ui/icons/AccessTime";
import { extractQueryParams } from "helper-methods";
import { getCalledPatients, getSignedPatients } from "http-calls";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import "./tv-tables.scss";
import AutoPaginatedTable from "modules/tv-display/components/auto-paginated-table/auto-paginated-table";

let clockRef = null;
let intervalRef = null;
let isFirstLoad = true;
let signedPatientsTracker = {};

const TvTables = (props) => {
  const [signedPatients, setSignedPatients] = useState([]);
  const [calledPatients, setCalledPatients] = useState([]);
  const [clinicSettings, setClinicSettings] = useState({
    clinicId: -1,
  });
  const [currentTime, setCurrentTime] = useState(moment().format("MMM Do LTS"));
  const [currentTimeStamp, setCurrentTimeStamp] = useState(moment());

  const _loadPatients = async () => {
    _initiateBackgroundRefresher();
    try {
      const signedPatients = await getSignedPatients(clinicSettings.clinicId);
      setSignedPatients(signedPatients);
      if (isFirstLoad) {
        isFirstLoad = false
      }
    } catch (error) {
      props.hideLoader();
      setSignedPatients([]);
      if (isFirstLoad) {
        isFirstLoad = false
      }
    }
    try {
      const calledPatients = await getCalledPatients(clinicSettings.clinicId);
      setCalledPatients(calledPatients);
    } catch (error) {
      props.hideLoader();
      setCalledPatients([]);
    }
    props.hideLoader();
  };

  const _initiateBackgroundRefresher = () => {
    if (!intervalRef) {
      intervalRef = setInterval(() => {
        _loadPatients();
      }, 5000);
    }
  };

  const _extractClinicId = () => {
    const params = extractQueryParams();
    const clinicIdKey = Object.keys(params).find(
      (param) => param.toLocaleLowerCase() === "clinicid"
    );
    const rowsPerViewKey = Object.keys(params).find(
      (param) => param.toLocaleLowerCase() === "rowsperview"
    );
    const intervalInSecondsKey = Object.keys(params).find(
      (param) => param.toLocaleLowerCase() === "intervalinseconds"
    );
    const updatedClinicSettings = { ...clinicSettings };
    if (clinicIdKey) {
      updatedClinicSettings.clinicId = params[clinicIdKey];
    }
    if (
      rowsPerViewKey &&
      !isNaN(params[rowsPerViewKey]) &&
      parseInt(params[rowsPerViewKey]) > 0
    ) {
      updatedClinicSettings.rowsPerView = params[rowsPerViewKey];
    }
    if (
      intervalInSecondsKey &&
      !isNaN(params[intervalInSecondsKey]) &&
      parseInt(params[intervalInSecondsKey]) > 0
    ) {
      updatedClinicSettings.intervalInSeconds = params[intervalInSecondsKey];
    }
    console.log("updatedClinicSettings :>> ", updatedClinicSettings);
    setClinicSettings(updatedClinicSettings);
  };

  const _getTimeDifference = (signInTime) => {
    const diff = moment.duration(currentTimeStamp.diff(moment(signInTime)));
    return diff.hours() + " hrs " + diff.minutes() + " mins ";
  };

  const _formatName = (name) => {
    // First get length
    const stringLength = name.length;
    if (stringLength <= 3) {
      return name;
    } else {
      let formattedName = name.substring(0, 3);
      formattedName += name.substring(3, stringLength).replace(/./g, "*");
      return _capitalize(formattedName);
    }
  };

  const _capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };

  const _getRowClassColor = (patient) => {
    const diffInMinutes = currentTimeStamp.diff(
      moment(patient.SignInTime),
      "minutes"
    );
    if (diffInMinutes > 25) {
      return " redRow";
    } else if (diffInMinutes > 15) {
      return " yellowRow";
    }
  };

  const _notifyOnNewPatientSignIn = (notify = true) => {
    if (!signedPatients.length) {
      signedPatientsTracker = {};
    } else {
      let updatesAvailable = false;
      // Check if new patients are added with signin status
      signedPatients.forEach(patient => {
        if (patient.Status === 'SignedIn') {
          if (!signedPatientsTracker[patient.PatientID]) {
            updatesAvailable = true;
            signedPatientsTracker[patient.PatientID] = true
          }
        }
      })
      if (notify && updatesAvailable) {
        _playSound();
      }
    }
  }

  const _playSound = () =>  {
    const audio = new Audio(require("../../../../assets/sounds/kiosk-notification.wav"));
    audio.play();
  }

  useEffect(() => {
    _extractClinicId();
    clockRef = setInterval(() => {
      setCurrentTime(moment().format("MMM Do  -  LTS"));
      setCurrentTimeStamp(moment());
    }, 1000);
    return () => {
      clearInterval(clockRef);
    };
  }, []);

  useEffect(() => {
    if (clinicSettings.clinicId !== -1) {
      props.showLoader("Loading");
      _loadPatients();
    }
  }, [clinicSettings]);

  useEffect(() => {
    if (!isFirstLoad) {
      _notifyOnNewPatientSignIn(true);
    } else {
      _notifyOnNewPatientSignIn(false);
    }
  }, [signedPatients])

  const formattedSignedInTableRows = signedPatients.map((signedPatient) => {
    const formattedRow = {
      cells: [],
      rowClassName: _getRowClassColor(signedPatient),
    };
    formattedRow.cells = [
      _formatName(signedPatient.FirstName),
      _formatName(signedPatient.LastName),
      signedPatient.CallNumber,
      signedPatient.VisitType.toUpperCase(),
      signedPatient.Status ? signedPatient.Status : "--",
      _getTimeDifference(signedPatient.StageTime),
      _getTimeDifference(signedPatient.SignInTime),
    ];
    return formattedRow;
  });
  const formattedCalledInTableRows = calledPatients.map((calledPatient) => {
    const formattedRow = {
      cells: [],
      rowClassName: _getRowClassColor(calledPatient),
    };
    formattedRow.cells = [
      _formatName(calledPatient.FirstName),
      _formatName(calledPatient.LastName),
      calledPatient.CallNumber,
      calledPatient.VisitType.toUpperCase(),
      calledPatient.Status ? calledPatient.Status : "--",
      _getTimeDifference(calledPatient.StageTime),
      _getTimeDifference(calledPatient.SignInTime),
    ];
    return formattedRow;
  });
  return (
    <>
      <div id="tvTablesWrapper">
        <div className="tvHeader">
          <div>Checkins</div>
          <div className="clockWrapper">
            <AccessTime /> {currentTime}
          </div>
        </div>
        {clinicSettings.clinicId > -1 ? (
          <div className="tableWrapper">
            {clinicSettings.rowsPerView && clinicSettings.intervalInSeconds ? (
              <>
                <AutoPaginatedTable
                  tableHeaders={[
                    "First Name",
                    "Last Name",
                    "Call Number",
                    "Reason for Visit",
                    "Status",
                    "Wait Time per stage",
                    "Wait Time of visit",
                  ]}
                  tableRows={formattedSignedInTableRows}
                  noPatientMessage="No Signed In Patients"
                  tableTitle="Signed Patients"
                  rowsPerView={clinicSettings.rowsPerView}
                  refreshIntervalInSeconds={clinicSettings.intervalInSeconds}
                />
                <AutoPaginatedTable
                  tableHeaders={[
                    "First Name",
                    "Last Name",
                    "Call Number",
                    "Reason for Visit",
                    "Status",
                    "Wait Time per stage",
                    "Wait Time of visit",
                  ]}
                  tableRows={formattedCalledInTableRows}
                  noPatientMessage="No Called In Patients"
                  tableTitle="Called Patients"
                  rowsPerView={clinicSettings.rowsPerView}
                  refreshIntervalInSeconds={clinicSettings.intervalInSeconds}
                />
              </>
            ) : (
              <>
                <div className="table">
                  <div className="tableHeader">Signed Patients</div>
                  <table>
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Call Number</th>
                        <th>Reason for Visit</th>
                        <th>Status</th>
                        <th>Wait Time per stage</th>
                        <th>Wait Time of visit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {signedPatients.map((patient, patientIndex) => (
                        <tr
                          key={patientIndex}
                          className={_getRowClassColor(patient)}
                        >
                          <td>{_formatName(patient.FirstName)}</td>
                          <td>{_formatName(patient.LastName)}</td>
                          <td>{patient.CallNumber}</td>
                          <td>{patient.VisitType.toUpperCase()}</td>
                          <td>{patient.Status ? patient.Status : "--"}</td>
                          <td>{_getTimeDifference(patient.StageTime)}</td>
                          <td>{_getTimeDifference(patient.SignInTime)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {!signedPatients.length ? (
                    <div id="noCell">No Signed Patients Available</div>
                  ) : null}
                </div>
                <div className="table">
                  <div className="tableHeader">Called Patients</div>
                  <table>
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Call Number</th>
                        <th>Reason for Visit</th>
                        <th>Status</th>
                        <th>Wait Time per stage</th>
                        <th>Wait Time of visit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {calledPatients.map((patient, patientIndex) => (
                        <tr key={patientIndex}>
                          <td>{_formatName(patient.FirstName)}</td>
                          <td>{_formatName(patient.LastName)}</td>
                          <td>{patient.CallNumber}</td>
                          <td>{patient.VisitType.toUpperCase()}</td>
                          <td>{patient.Status ? patient.Status : "--"}</td>
                          <td>{_getTimeDifference(patient.StageTime)}</td>
                          <td>{_getTimeDifference(patient.SignInTime)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {!calledPatients.length ? (
                    <div id="noCell">No Called Patients Available</div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="clinicIdError" style={{ height: "100vh" }}>
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p>
              This seems to be a configuration error. <br /> Please notify the
              front desk.
            </p>
          </div>
        )}{" "}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

export default connect(null, mapDispatchToProps)(TvTables);
