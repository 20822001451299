import { authProvider } from "config/authProvider";
import { extractQueryParams } from "helper-methods";
import { getClinicUsers, getClinicRoles } from "http-calls";
// import LogoutNotifier from "modules/general/components/logout-notifier/logout-notifier";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import "../../style.scss";
import Header from "../../../general/containers/header/header";
import "./user-management-table.scss";
import { getClinicStaffList, getSTaffUserInfo } from "http-calls";
import UserEditor from "modules/user-management-module/components/user-editor/user-editor";
import { updateUser, addUser } from "http-calls";
import { getAllAzureUsers } from "http-calls";
import { getGraphApiToken } from "http-calls";
import MaterialSelect from "modules/general/components/material-select/material-select";
import { getClinicList } from "http-calls";
import { deepClone } from "helper-methods";
import SearchInput from "modules/general/components/search-input/search-input";

const UserManagementTable = (props) => {
  const [users, setUsers] = useState([]);
  const [clinicId, setClinicId] = useState("-1");
  const [clinics, setClinics] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [roles, setRoles] = useState([]);
  const [isLogoutNotifierVisible, setIsLogoutNotifierVisible] = useState(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [adUsers, setAdUsers] = useState([]);
  const [graphToken, setGraphToken] = useState(null);

  const [filteredRows, setFilteredRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const _updatePatientStatus = async (id, status) => {};

  const _setClinicIdFromRedux = () => {
    setClinicId(props.userData.selectedClinic.ClinicID);
  };

  const _loadUserData = async () => {
    const userInfo = authProvider.getAccountInfo();
    setUserInfo(userInfo);
  };

  const _loadData = async () => {
    try {
      props.showLoader("Loading Clinic Users");
      const roles = await getClinicRoles(clinicId);
      try {
        const users = await getClinicUsers(clinicId);
        setUsers(users);
      } catch (error) {
        setUsers([]);
      }
      if (!clinics.length) {
        await _fetchClinics();
      }
      if (!adUsers.length) {
        await _fetchAzureAdUsers();
      }
      setRoles(roles);
      props.hideLoader();
    } catch (error) {}
  };

  const _logout = () => {
    // setIsLogoutNotifierVisible(true);
  };

  const _checkIfUserHasAccess = async () => {
    const userInfo = authProvider.getAccountInfo();
    // console.log('userInfo :>> ', userInfo);
    try {
      if (userInfo && userInfo.account && userInfo.account.userName) {
        const res = await getSTaffUserInfo(userInfo.account.userName);
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error :>> ", error);
      _logout();
    }
  };

  const _showUserEditPrompt = (user) => {
    setSelectedUser(user);
    setIsUserModalVisible(true);
  };

  const _showUserAddPrompt = () => {
    setIsUserModalVisible(true);
  };

  const _hideUserEditPrompt = () => {
    setSelectedUser(null);
    setIsUserModalVisible(false);
  };

  const _filterRows = () => {
    let filteredRows = deepClone(users);
    const lowerCasedSearchValue = searchValue.toLowerCase().trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredRows = filteredRows.filter((row) => {
        if (row.Username.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if (
          `${row.FirstName} ${row.LastName}`
            .toLowerCase()
            .indexOf(lowerCasedSearchValue) > -1
        ) {
          return true;
        }
      });
    }
    setFilteredRows(filteredRows);
  };

  useEffect(() => {
    _filterRows();
  }, [users]);

  useEffect(() => {
    _filterRows();
  }, [searchValue]);

  const _updateUser = async (updatedUser) => {
    _hideUserEditPrompt();
    props.showBottomLoader("Updating User");
    updatedUser.ClinicID = props.userData.selectedClinic.ClinicID;
    console.log("updatedUser :>> ", updatedUser);
    try {
      await updateUser(updatedUser);
      await _loadData();
      props.hideLoader();
    } catch (error) {
      props.hideLoader();
    }
  };

  const _addUser = async (newUser) => {
    _hideUserEditPrompt();
    props.showBottomLoader("Adding new user");
    newUser.ClinicID = clinicId;
    try {
      await addUser(newUser);
      await _loadData();
      props.hideLoader();
    } catch (error) {
      props.hideLoader();
    }
  };

  const _fetchAzureAdUsers = async () => {
    try {
      // First get graph token
      const response = await getGraphApiToken();
      const { access_token: graphToken } = JSON.parse(response);
      // Now fetch user lists from azure ad
      setGraphToken(graphToken);
    } catch (error) {}
  };

  const _fetchClinics = async () => {
    try {
      props.showLoader("Loading Clinic Information");
      const clinics = await getClinicList();
      const allowedClinicIds = await _getAllowedClinics();
      // Filter allowed clinics
      const allowedClinics = clinics.filter(
        (clinic) => allowedClinicIds.indexOf(clinic.ClinicID) > -1
      );
      setClinics(allowedClinics);
      const params = extractQueryParams();
      if (params && params.clinicId) {
        setClinicId(params.clinicId);
      } else {
        setClinicId(allowedClinics[0].ClinicID);
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const _getAllowedClinics = async () => {
    try {
      const userInfo = authProvider.getAccountInfo();
      if (userInfo && userInfo.account && userInfo.account.userName) {
        const res = await getSTaffUserInfo(userInfo.account.userName);
        const allowedClinicIds = [];
        res.forEach((userAccess) => {
          if (userAccess.UserAdminRole === "SuperAdmin") {
            if (allowedClinicIds.indexOf(userAccess.ClinicID) === -1) {
              allowedClinicIds.push(userAccess.ClinicID);
            }
          }
        });
        return allowedClinicIds;
      } else {
        throw "error";
      }
    } catch (error) {}
  };

  useEffect(() => {
    _checkIfUserHasAccess();
    _fetchClinics();
    // const clinicId = 1;
    // setClinicId(clinicId);
  }, []);

  useEffect(() => {
    if (clinicId !== "-1") {
      _loadUserData();
    }
  }, [clinicId]);

  useEffect(() => {
    if (userInfo) {
      props.showLoader("Loading");
      _loadData();
    }
  }, [userInfo]);

  const clinicOptions = clinics.map((c) => ({
    label: c.Name,
    key: c.ClinicID,
  }));

  return (
    <>
      {/* <LogoutNotifier isVisible={isLogoutNotifierVisible} /> */}
      <Header>
        <div id="labAssistantView" className="userActivationWrapper">
          <div className="clinicTableAction spaceBetween">
            <SearchInput value={searchValue} onChange={setSearchValue} />
            <div className="usersOptionsWrapper">
              <div className="clinicSelectorWrapper">
                {clinicOptions &&
                  clinicOptions.length &&
                  clinicId &&
                  clinicId > -1 && (
                    <MaterialSelect
                      options={clinicOptions}
                      value={clinicId}
                      label={"Clinic"}
                      onChange={(clinicID) => setClinicId(clinicID)}
                    />
                  )}
              </div>
              <button className="newButton" onClick={_showUserAddPrompt}>
                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; &nbsp;
                Add User
              </button>
            </div>
          </div>
          <div className="tableInternalScroll">
            <table>
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Name</th>
                  <th>User Type</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredRows.map((user) => (
                  <tr>
                    <td>{user.Username}</td>
                    <td>{user.FirstName + " " + user.LastName}</td>
                    <td>{user.UserAdminRole}</td>
                    <td>
                      {user.Active ? (
                        <div className="enabled">
                          <i class="fa fa-check-circle" aria-hidden="true"></i>
                          &nbsp; Active
                        </div>
                      ) : (
                        <div className="disabled">
                          <i className="fa fa-times" aria-hidden="true"></i>
                          &nbsp; Inactive
                        </div>
                      )}
                    </td>
                    <td>
                      <button
                        className="editButton"
                        disabled={!user.Active}
                        onClick={(e) => {
                          if (user.Active) {
                            _showUserEditPrompt(user);
                          }
                        }}
                      >
                        <i className="fa fa-pencil" aria-hidden="true"></i>{" "}
                        &nbsp; Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {filteredRows.length === 0 && (
            <div id="noCell">No Users Available</div>
          )}
        </div>
      </Header>
      <UserEditor
        isVisible={isUserModalVisible}
        editMode={selectedUser ? true : false}
        selectedUser={selectedUser}
        onUserUpdate={_updateUser}
        onNewUserAdd={_addUser}
        onDiscard={_hideUserEditPrompt}
        roles={roles}
        adUsers={adUsers}
        graphToken={graphToken}
        existingUsers={users}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementTable);
