import Button from "components/CustomButtons/Button.js";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { checkIfPatientExists } from "../../../../http-calls/index";
import LanguageParsedText from "../../../../multi-lang/language-parser";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import { updateWizardData } from "../../../../redux/actions/wizard-data";
import Numpad from "modules/general/components/numpad/numpad";
import "./phone-input.scss";

const PhoneInput = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const _updatePhoneNumber = (maskedValue) => {
    let unMaskedPhoneNumber = maskedValue.replace("(", "");
    unMaskedPhoneNumber = unMaskedPhoneNumber.replace(")", "");
    unMaskedPhoneNumber = unMaskedPhoneNumber.replace(" ", "");
    unMaskedPhoneNumber = unMaskedPhoneNumber.replace("-", "");
    unMaskedPhoneNumber = unMaskedPhoneNumber.split("_").join("");
    setPhoneNumber(unMaskedPhoneNumber);
  };

  const _parseCallNumber = (patientAddResponse) => {
    const parts = patientAddResponse.split("CallNumber: ");
    return parts[1];
  };

  const _submit = async (e) => {
    e.preventDefault();
    setIsTouched(true);
    _validate();
    // Validate if number is proper
    if (isValid) {
      try {
        // Check if already signed in
        props.showLoader("Please wait");
        const response = await checkIfPatientExists(
          phoneNumber,
          props.clinicId
        );
        props.hideLoader();
        console.log("response :>> ", response);
        const callNumber = _parseCallNumber(response);
        console.log("callNumber :>> ", callNumber);
        props.showRegistrationSucessPage(callNumber, true);
      } catch (error) {
        console.log("error :>> ", error);
        props.hideLoader();
        if (error.status === 400) {
          props.updateWizardData({
            phoneNumber,
          });
          props.proceedToStep("personalDataInput");
        }
      }
    }
  };

  const _validate = () => {
    if (phoneNumber.length !== 10) {
      // Not valid
      setIsValid(false);
      return;
    } else {
      // Valid
      setIsValid(true);
    }
  };

  const _appendCharToPhoneNumber = (number) => {
    let _phoneNumber = phoneNumber;
    if (phoneNumber.length < 10) {
      _phoneNumber += `${number}`;
      setPhoneNumber(_phoneNumber);
    }
  };

  const _performDeleteCharOnPhoneNumber = () => {
    let _phoneNumber = phoneNumber.slice(0, -1);
    setPhoneNumber(_phoneNumber);
  };


  useEffect(() => {
    _validate();
  }, [phoneNumber]);

  return (
    <div id="phoneInputOuterWrapper">
      <div id="phoneInputInnerWrapper">
        <p>
          <LanguageParsedText value="requiredfields" />
        </p>
        <h3>
          <LanguageParsedText value="phonenumber" /> (
          <LanguageParsedText value="phoneformat" />) &nbsp; *
        </h3>
        <div className="inputWrapper">
          {/* <input type="number" step="0.01" /> */}
          <InputMask
            {...props}
            mask="(999) 999-9999"
            // alwaysShowMask
            value={phoneNumber}
            onChange={(e) => _updatePhoneNumber(e.target.value)}
            placeholder={"(___) ___-____"}
            // onBlur={(e) => setIsTouched(true)}
            readOnly="readonly"
          />
        </div>
        {!isValid && isTouched ? (
          <p className="formError">
            <LanguageParsedText value="phonenowarning" />
          </p>
        ) : null}
        <Button color="success" size="lg" onClick={_submit} className="nextBtn">
          <LanguageParsedText value="next" />
        </Button>
        <Button
          color="danger"
          size="lg"
          onClick={() => props.proceedToStep("callNumberInput")}
          className="noPhoneBtn"
        >
          <LanguageParsedText value="donthavephonenumber" />
        </Button>
      </div>
      <Numpad
        onDelete={(e) => _performDeleteCharOnPhoneNumber()}
        onKeyPress={(number) => _appendCharToPhoneNumber(number)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wizardData: state.wizardData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateWizardData: (wizardData) => dispatch(updateWizardData(wizardData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneInput);
