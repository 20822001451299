import React, { Component } from "react";
import "./patients-visit-details-table.scss";
import PatientLogViewerModal from "../patient-log-viewer-modal/patient-log-viewer-modal";

class PatientsVisitDetailsTable extends Component {
  state = {
    logPrompt: {
      isVisible: false,
      selectedPatient:null
    }
  };

  _calculateSumOf = stage => {
    const { tableData } = this.props;
    let sum = 0;
    tableData.forEach(row => {
      if (row.times[stage] && !isNaN(row.times[stage])) {
        sum += parseFloat(row.times[stage])
      }
    })
    return this._toFixedIfNecessary(sum);
  }

  _showLogPrompt = patient => {
    this.setState({ logPrompt: {
      isVisible: true,
      selectedPatient: patient
    } });
  }

  _hideLogPrompt = () => {
    this.setState({ logPrompt: {
      isVisible: false,
      selectedPatient: null
    } });
  }

  _toFixedIfNecessary = (value, dp = 2) => {
    return +parseFloat(Math.abs(value)).toFixed(dp);
  };

  render() {
    const { logPrompt } = this.state;
    const { tableData } = this.props;
    return (
      <>
      <PatientLogViewerModal 
        isVisible={logPrompt.isVisible}
        patient={logPrompt.selectedPatient}
        onDismiss={this._hideLogPrompt}
      />
        <div className="patientVisitDetailsTable">
          <div className="tableHeaderRow">
            <div className="headerCell f2">Registered By</div>
            <div className="headerCell f2">Called By</div>
            <div className="headerCell">Call Number</div>
            <div className="headerCell f2">First Name</div>
            <div className="headerCell f2">Last Name</div>
            <div className="headerCell">Sign To Register</div>
            <div className="headerCell">Register To OrderActivation</div>
            <div className="headerCell">OrderActivation To PendColl</div>
            <div className="headerCell">PendColl To CallTime</div>
            <div className="headerCell">CallTime To Comptime</div>
            <div className="headerCell">Total Completion</div>
            <div className="headerCell">No Answer Time</div>
            <div className="headerCell">Order Issue Time</div>
          </div>
          <div className="tableBody">
            {tableData.map((row, rowIndex) => (
              <div className="tableBodyRow" key={rowIndex} onClick={e => {
                this._showLogPrompt(row.patientData)
              }}>
                <div className="bodyCell f2">{row.registeredBy}</div>
                <div className="bodyCell f2">{row.calledBy}</div>
                <div className="bodyCell">{row.patientId}</div>
                <div className="bodyCell f2">{row.firstName}</div>
                <div className="bodyCell f2">{row.lastName}</div>
                <div className="bodyCell">{row.times.signInToRegister}</div>
                <div className="bodyCell">
                  {row.times.registerToOrderActivation}
                </div>
                <div className="bodyCell">
                  {row.times.orderActivationToPendingCollection}
                </div>
                <div className="bodyCell">
                  {row.times.pendingCollectionToCalled}
                </div>
                <div className="bodyCell">{row.times.calledToCompleted}</div>
                <div className="bodyCell">{row.times.totalCompletion}</div>
                <div className="bodyCell">{row.times.noAnswer}</div>
                <div className="bodyCell">{row.times.orderIssueTime}</div>
              </div>
            ))}
          </div>
          <div className="sumRow">
            <div className="sumCell totalCell">Total</div>
            <div className="sumCell valueCell">{this._calculateSumOf("signInToRegister")}</div>
            <div className="sumCell valueCell">{this._calculateSumOf("registerToOrderActivation")}</div>
            <div className="sumCell valueCell">{this._calculateSumOf("orderActivationToPendingCollection")}</div>
            <div className="sumCell valueCell">{this._calculateSumOf("orderActivationToPendingCollection")}</div>
            <div className="sumCell valueCell">{this._calculateSumOf("pendingCollectionToCalled")}</div>
            <div className="sumCell valueCell lastCell">&nbsp;</div>
          </div>
        </div>
      </>
    );
  }
}

export default PatientsVisitDetailsTable;
