import Sync from "@material-ui/icons/Sync";
import { extractQueryParams } from "helper-methods";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import { clearWizardData } from "../../redux/actions/wizard-data";
import AppointmentTypeSelector from "./components/appointment-type-selector/appointment-type-selector";
import CallNumberInput from "./components/call-number-input/call-number-input";
import LanguageSelector from "./components/language-selector/language-selector";
import PersonalDataInput from "./components/personal-data-input/personal-data-input";
import PhoneInput from "./components/phone-input/phone-input";
import ProgressBar from "./components/progress-bar/progress-bar";
import RegistrationSuccess from "./components/registration-success/registration-success";
import Header from "./containers/header/header";
import LanguageParsedText from "../../multi-lang/language-parser";
import { getVisitReasons } from "http-calls";
import { getVisitSystemSettingsOptions } from "http-calls";

class RegistrationModule extends Component {
  state = {
    currentView: "languageSelector",
    callNo: "",
    isPreviousRegistration: false,
    clinicId: -1,
    patientVisitId: null,
    visitSettings: [],
    visitReasons: [],
  };

  async componentDidMount() {
    this.props.showLoader("Please wait");
    try {
      await this._extractClinicId();
      await this._fetchVisitSettings();
      await this._fetchVisitReasons();
      this.props.hideLoader();
    } catch (error) {
      this.props.hideLoader();
    }
  }

  _fetchVisitReasons = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let visitReasons = [];
        const { clinicId } = this.state;
        const response = await getVisitReasons(clinicId);
        if (response && response.length) {
          visitReasons = response.map((reason) => reason.ReasonforVisit);
        }
        this.setState({ visitReasons }, () => {
          resolve();
        });
      } catch (error) {
        resolve();
      }
    });
  };

  _fetchVisitSettings = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { clinicId } = this.state;
        const visitSettings = await getVisitSystemSettingsOptions(clinicId);
        this.setState({ visitSettings }, () => {
          resolve();
        });
      } catch (error) {
        resolve();
      }
    });
  };

  _extractClinicId = () => {
    return new Promise((resolve, reject) => {
      const params = extractQueryParams();
      const clinicId = Object.keys(params).find(
        (param) => param.toLocaleLowerCase() === "clinicid"
      );
      if (clinicId) {
        this.setState({ clinicId: params[clinicId] }, () => {
          resolve();
        });
      } else {
        reject();
      }
    });
  };

  _showRegistrationSucessPage = (
    callNo,
    isPreviousRegistration,
    patientVisitId = null
  ) => {
    this.setState({
      currentView: "registrationSuccess",
      callNo,
      patientVisitId,
      isPreviousRegistration,
    });
  };

  _proceedToStep = (step) => {
    this.props.showLoader("Loading");
    setTimeout(() => {
      this.setState({ currentView: step });
      this.props.hideLoader();
    }, 1200);
  };

  _renderView = () => {
    const { currentView, visitReasons, visitSettings } = this.state;
    switch (currentView) {
      case "languageSelector": {
        return (
          <LanguageSelector
            proceedToStep={this._proceedToStep}
            updateSelectedLanguage={(l) => {}}
            visitSettings={visitSettings}
          />
        );
      }
      case "phoneInput": {
        return (
          <PhoneInput
            clinicId={this.state.clinicId}
            proceedToStep={this._proceedToStep}
            showRegistrationSucessPage={this._showRegistrationSucessPage}
            visitSettings={visitSettings}
          />
        );
      }
      case "callNumberInput": {
        return (
          <CallNumberInput
            clinicId={this.state.clinicId}
            proceedToStep={this._proceedToStep}
            showRegistrationSucessPage={this._showRegistrationSucessPage}
            visitSettings={visitSettings}
          />
        );
      }
      case "personalDataInput": {
        return (
          <PersonalDataInput
            proceedToStep={this._proceedToStep}
            visitSettings={visitSettings}
          />
        );
      }
      case "appointmentTypeSelection": {
        return (
          <AppointmentTypeSelector
            visitReasons={visitReasons}
            clinicId={this.state.clinicId}
            proceedToStep={this._proceedToStep}
            showRegistrationSucessPage={this._showRegistrationSucessPage}
            visitSettings={visitSettings}
          />
        );
      }
      case "registrationSuccess": {
        return (
          <RegistrationSuccess
            proceedToStep={this._proceedToStep}
            callNo={this.state.callNo}
            patientVisitId={this.state.patientVisitId}
            onRestart={this._restart}
            isPreviousRegistration={this.state.isPreviousRegistration}
            visitSettings={visitSettings}
          />
        );
      }
    }
  };

  _restart = () => {
    this.props.clearWizardData();
    this.setState({ currentView: "languageSelector" });
  };

  _getCurrentStep = () => {
    const { currentView } = this.state;
    switch (currentView) {
      case "phoneInput": {
        return 0;
      }
      case "callNumberInput": {
        return 0;
      }
      case "personalDataInput": {
        return 1;
      }
      case "appointmentTypeSelection": {
        return 2;
      }
      case "registrationSuccess": {
        return -1;
      }
      default: {
        return -2;
      }
    }
  };

  render() {
    return (
      <>
        <Header
          {...this.props}
          hideLanguagePrompt={this._getCurrentStep() === -2}
        />
        {this.state.clinicId > -1 ? (
          <div id="wizardWrapper">
            {this._getCurrentStep() > -1 ? (
              <>
                <ProgressBar
                  totalSteps={3}
                  currentStep={this._getCurrentStep()}
                />
              </>
            ) : null}
            {this._getCurrentStep() > -2 && (
              <button id="startOver" onClick={this._restart}>
                <Sync />{" "}
                <span id="newRegistartion">
                  <LanguageParsedText value="startover" />
                </span>
              </button>
            )}
            {this._renderView()}
          </div>
        ) : (
          <div className="clinicIdError" style={{ height: "100vh" }}>
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p>
              This seems to be a configuration error. <br /> Please notify the
              front desk.
            </p>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    clearWizardData: () => dispatch(clearWizardData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationModule);
