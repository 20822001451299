import { authProvider } from "config/authProvider";
import { deepClone } from "helper-methods";
import { getSTaffUserInfo } from "http-calls";
import { getOrderActivations, updatePatientStatus, getVisitReasons } from "http-calls/index";
import ExtendedActions from "modules/general/components/extended-actions/extended-actions";
import LogoutNotifier from "modules/general/components/logout-notifier/logout-notifier";
import SearchInput from "modules/general/components/search-input/search-input";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import Header from "../../../general/containers/header/header";
import "../../style.scss";
import MaterialSelect from "modules/general/components/material-select/material-select";


const actions = [
  {
    label: "Select an Action",
    key: null,
  },
  {
    label: "Pending Collection",
    key: "Pending Collection",
  },
  {
    label: "Order issues",
    key: "Order issues",
  },
];

let intervalRef = null;
let userComparer = [];

const OrderActivationTable = (props) => {
  const [users, setUsers] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [lastFetchedOn, setLastFetchedOn] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [clinicId, setClinicId] = useState("-1");
  const [userInfo, setUserInfo] = useState(null);
  const [visitReasons, setVisitReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState("All");
  const [isLogoutNotifierVisible, setIsLogoutNotifierVisible] = useState(false);
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const _fetchOrderActivatedPatients = async () => {
    _initiateBackgroundRefresher();
    setLastFetchedOn(+new Date());
    let users = []
    try {
      const usersWithStatus3 = await getOrderActivations(props.clinicId, 3);
      users = [...users, ...usersWithStatus3];
      // userComparer = _mergeUsers(users);
      // setUsers(userComparer);
    } catch (error) {
      console.log("error :>> ", error);
      // setUsers([]);
    }
    try {
      const usersWithStatus9 = await getOrderActivations(props.clinicId, 9);
      users = [...users, ...usersWithStatus9];
      // userComparer = _mergeUsers(users);
      // setUsers(userComparer);
    } catch (error) {
      console.log("error :>> ", error);
      // setUsers([]);
    }
    if (users.length) {
      userComparer = _mergeUsers(users);
      setUsers(userComparer);
    } else {
      setUsers([]);
    }
  };

  const _mergeUsers = (newUsers) => {
    return newUsers.map((patient) => {
      // Check if patinet already exists in state
      const previousData = userComparer.find(
        (p) => p.PatientID === patient.PatientID
      );
      if (previousData) {
        patient.FIN = previousData.FIN;
        patient.internalcomments = previousData.internalcomments;
      }
      return patient;
    });
  };

  const _updateComment = (id, comment) => {
    setHasChanges(true);
    const patientIndex = users.findIndex((p) => p.PatientID === id);
    users[patientIndex] = {
      ...users[patientIndex],
      internalcomments: comment,
    };
    userComparer = users;
    setUsers([...users]);
  };

  const _updateFin = (id, fin) => {
    setHasChanges(true);
    const patientIndex = users.findIndex((p) => p.PatientID === id);
    users[patientIndex] = {
      ...users[patientIndex],
      FIN: fin,
    };
    userComparer = users;
    setUsers([...users]);
  };

  const _updatePatientStatus = async (patient, status) => {
    if (status) {
      // Call API to update the status
      props.showBottomLoader("Updating");
      let PatientStatusTypeID = 0;
      if (status === "Order issues") {
        PatientStatusTypeID = 7;
      } else if (status === "Pending Collection") {
        PatientStatusTypeID = 4;
      } else if (status === "Lab Order Issues") {
        PatientStatusTypeID = 9;
      }
      await updatePatientStatus({
        ...patient,
        UpdatedBy: userInfo.account.userName,
        PatientStatusTypeID,
      });
      await _fetchOrderActivatedPatients();
      props.hideLoader();
    }
  };

  const _loadUserData = async () => {
    const userInfo = authProvider.getAccountInfo();
    setUserInfo(userInfo);
  };

  const _refresh = async () => {
    props.showBottomLoader("Refreshing list");

    await _fetchOrderActivatedPatients();
    props.hideLoader();
  };

  const _loadData = async () => {
    await _fetchOrderActivatedPatients();
    props.hideLoader();
  };

  const _initiateBackgroundRefresher = () => {
    if (!intervalRef) {
      intervalRef = setInterval(() => {
        _fetchOrderActivatedPatients();
      }, 5000);
    }
  };

  const _logout = () => {
    setIsLogoutNotifierVisible(true);
  };

  const _checkIfUserHasAccess = async () => {
    const userInfo = authProvider.getAccountInfo();
    // console.log('userInfo :>> ', userInfo);
    try {
      if (userInfo && userInfo.account && userInfo.account.userName) {
        const res = await getSTaffUserInfo(userInfo.account.userName);
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error :>> ", error);
      _logout();
    }
  };

  const _filterRows = () => {
    let filteredRows = deepClone(users);
    const lowerCasedSearchValue = searchValue.toLowerCase().trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredRows = filteredRows.filter((row) => {
        if (row.CallNumber.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if (
          `${row.FirstName} ${row.LastName}`
            .toLowerCase()
            .indexOf(lowerCasedSearchValue) > -1
        ) {
          return true;
        }
      });
    }
    if (selectedReason !== "All") {
      filteredRows = filteredRows.filter(row => row.VisitType === selectedReason)
    }
    setFilteredRows(filteredRows);
  };

  const _loadVisitReasons = async () => {
    try {
      let visitReasons = [{
        label: "All",
        key: "All",
      }];
      const response = await getVisitReasons(props.clinicId);
      if (response && response.length) {
        visitReasons = [...visitReasons, ...response.map((reason) => ({
          label: reason.ReasonforVisit,
          key: reason.ReasonforVisit,
        }))];
      }
      setVisitReasons(visitReasons);
    } catch (error) {
      
    }
  }

  useEffect(() => {
    _checkIfUserHasAccess();
    _loadVisitReasons();
    _loadUserData();
    // const clinicId = 1;
    // setClinicId(clinicId);
    return () => {
      clearInterval(intervalRef);
    };
  }, []);

  useEffect(() => {
    if (userInfo) {
      props.showLoader("Loading");
      _loadData();
    }
  }, [userInfo]);

  useEffect(() => {
    _filterRows();
    if (!isNaN(users.length) && props.setPatientCount) {
      props.setPatientCount(users.length)
    }
  }, [users]);

  useEffect(() => {
    _filterRows();
  }, [searchValue, selectedReason]);

  useEffect(() => {
    if (props.activeView === "orderActivationView") {
      props.showBottomLoader("Loading");
      _loadData();
    } else {
      setSearchValue("");
    }
  }, [props.activeView]);

  const patientActions = [
    {
      label: "Pending Collection",
      onClick: () => {
        setIsActionModalVisible(false);
        _updatePatientStatus(selectedPatient, "Pending Collection");
      },
    },
    {
      label: "Order issues",
      onClick: () => {
        setIsActionModalVisible(false);
        _updatePatientStatus(selectedPatient, "Order issues");
      },
    },
    {
      label: "Lab Order Issues",
      onClick: () => {
        setIsActionModalVisible(false);
        _updatePatientStatus(selectedPatient, "Lab Order Issues");
      },
    },
  ];

  const _showActionModal = (patient) => {
    setSelectedPatient(patient);
    setIsActionModalVisible(true);
  };
  if (props.activeView === "orderActivationView") {
    return (
      <>
        <ExtendedActions
          isVisible={isActionModalVisible}
          onCancel={(e) => setIsActionModalVisible(false)}
          actions={patientActions}
          modalHeight="470"
          selectedPatient={selectedPatient}
        />
          <div id="labAssistantView" className="userActivationWrapper">
            <div className="tableActions spaceBetween">
              <SearchInput value={searchValue} onChange={setSearchValue} />
              <div className="usersOptionsWrapper">
              <div className="clinicSelectorWrapper">
                {visitReasons &&
                  visitReasons.length ? (
                    <MaterialSelect
                      options={visitReasons}
                      value={selectedReason}
                      label={"Reason"}
                      onChange={(reason) => setSelectedReason(reason)}
                    />
                  ): <></>}
              </div>
            </div>
            </div>
            <div className="tableInternalScroll">
            <table>
              <thead>
                <tr>
                  <th>Call Number</th>
                  <th>Name</th>
                  <th>Reason for Visit</th>
                  <th>Comment</th>
                  <th>FIN</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredRows.map((user) => (
                  <tr key={user.PatientID}>
                    <td data-column="Call Number">{user.CallNumber}</td>
                    <td data-column="Name">
                      {user.FirstName + " " + user.LastName}
                    </td>
                    <td data-column="Reason for Visit">
                      {user.VisitType.toUpperCase()}
                    </td>
                    <td data-column="action">
                      <textarea
                        className="customInput"
                        value={user.internalcomments}
                        onChange={(e) =>
                          _updateComment(user.PatientID, e.target.value)
                        }
                      />
                    </td>
                    <td data-column="FIN" className="finCell">
                      <input
                        placeholder="Enter FIN No"
                        value={user.FIN}
                        onChange={(e) =>
                          _updateFin(user.PatientID, e.target.value)
                        }
                      />
                    </td>
                    <td data-column="Status">
                      <div className="buttonsWrapper">
                        <button
                          className="actions"
                          onClick={(e) => _showActionModal(user)}
                        >
                          <i className="fa fa-cog" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {filteredRows.length === 0 && (
              <div id="noCell">No Patients Available</div>
            )}
            </div>
          </div>
      </>
    );
  } else {
    return <></>
  }
  
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderActivationTable);
