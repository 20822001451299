import ExtendedActions from "modules/general/components/extended-actions/extended-actions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { hideLoader } from "redux/actions/loader-data";
import { authProvider } from "../../../../config/authProvider";
import {
  getMyRegistrations,
  updatePatientStatus,
  getVisitReasons,
} from "../../../../http-calls/index";
import {
  hideBottomLoader,
  showBottomLoader,
  showLoader,
} from "../../../../redux/actions/loader-data";
import MyRegistrationRow from "../my-registration-row/my-registration-row";
import SearchInput from "modules/general/components/search-input/search-input";
import { deepClone } from "helper-methods";
import MaterialSelect from "modules/general/components/material-select/material-select";

let intervalRef1 = null;
let registrations = [];

const MyRegistrations = (props) => {
  const [myRegistrations, setMyRegistrations] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [lastFetchedOn, setLastFetchedOn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [visitReasons, setVisitReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState("All");
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const _loadMyRegistrations = async () => {
    _initiateBackgroundRefresher();
    setLastFetchedOn(+new Date());
    const userInfo = authProvider.getAccountInfo();
    try {
      const myRegistrations = await getMyRegistrations(
        userInfo.account.userName,
        props.clinicId
      );
      registrations = _mergePatients([...myRegistrations]);
      setMyRegistrations(registrations);
      props.hideLoader();
    } catch (error) {
      console.log("error :>> ", error);
      props.hideLoader();
      setMyRegistrations([]);
    }
  };

  const _mergePatients = (newPatients) => {
    return newPatients.map((patient) => {
      // Check if patinet already exists in state
      const previousData = registrations.find(
        (p) => p.PatientID === patient.PatientID
      );
      if (previousData) {
        patient.FIN = previousData.FIN;
        patient.internalcomments = previousData.internalcomments;
      }
      return patient;
    });
  };

  const _updatePatient = (id, patient) => {
    const patientIndex = registrations.findIndex((p) => p.PatientID === id);
    registrations[patientIndex] = {
      ...registrations[patientIndex],
      ...patient,
    };
    setMyRegistrations([...registrations]);
  };

  const _updatePatientStatus = async (patient, status) => {
    if (status) {
      props.showBottomLoader("Updating");
      let PatientStatusTypeID = 0;
      if (status === "Return to registration queue") {
        PatientStatusTypeID = 1;
      } else if (status === "Pending Collection") {
        PatientStatusTypeID = 4;
      } else if (status === "Ready for order activation") {
        PatientStatusTypeID = 3;
      } else if (status === "Order Issues") {
        PatientStatusTypeID = 7;
      } else if (status === "Cancelled") {
        PatientStatusTypeID = 10;
      }
      await updatePatientStatus({
        ...patient,
        UpdatedBy: userInfo.account.userName,
        PatientStatusTypeID,
      });
      await _loadMyRegistrations();
      props.hideLoader();
    }
  };

  const _initiateBackgroundRefresher = () => {
    if (!intervalRef1) {
      intervalRef1 = setInterval(() => {
        _loadMyRegistrations();
      }, 5000);
    }
  };

  const _refresh = async () => {
    props.showBottomLoader("Refreshing");
    await _loadMyRegistrations();
    props.hideLoader();
  };

  const _loadData = async () => {
    props.showBottomLoader("Loading");
    await _loadMyRegistrations();
    props.hideLoader();
  };

  const _loadUserData = () => {
    const userInfo = authProvider.getAccountInfo();
    setUserInfo(userInfo);
  };

  const _filterRows = () => {
    let filteredRows = deepClone(myRegistrations);
    const lowerCasedSearchValue = searchValue.toLowerCase().trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredRows = filteredRows.filter((row) => {
        if (row.CallNumber.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if ((`${row.FirstName} ${row.LastName}`).toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
      });
    }
    if (selectedReason !== "All") {
      filteredRows = filteredRows.filter(row => row.VisitType === selectedReason)
    }
    setFilteredRows(filteredRows);
  };

  const _loadVisitReasons = async () => {
    try {
      let visitReasons = [{
        label: "All",
        key: "All",
      }];
      const response = await getVisitReasons(props.clinicId);
      if (response && response.length) {
        visitReasons = [...visitReasons, ...response.map((reason) => ({
          label: reason.ReasonforVisit,
          key: reason.ReasonforVisit,
        }))];
      }
      setVisitReasons(visitReasons);
    } catch (error) {
      
    }
  }

  useEffect(() => {
    _loadUserData();
    _loadVisitReasons();
    return () => {
      clearInterval(intervalRef1);
      intervalRef1 = null;
    };
  }, []);

  useEffect(() => {
    if (userInfo) {
      _loadData();
    }
  }, [userInfo]);

  useEffect(() => {
    if (props.activeView === "allCheckins") {
      setSearchValue("");
    }
  }, [props.activeView]);

  useEffect(() => {
    _filterRows();
    if (!isNaN(myRegistrations.length) && props.setPatientCount) {
      props.setPatientCount(myRegistrations.length)
    }
  }, [myRegistrations]);

  useEffect(() => {
    _filterRows();
  }, [searchValue, selectedReason]);

  const _showActionModal = (patient) => {
    setSelectedPatient(patient);
    setIsActionModalVisible(true);
  };

  const patientActions = [
    {
      label: "Return to registration queue",
      onClick: () => {
        setIsActionModalVisible(false);
        _updatePatientStatus(selectedPatient, "Return to registration queue");
      },
    },
    {
      label: "Pending Collection",
      onClick: () => {
        setIsActionModalVisible(false);
        _updatePatientStatus(selectedPatient, "Pending Collection");
      },
    },
    {
      label: "Ready for order activation",
      onClick: () => {
        setIsActionModalVisible(false);
        _updatePatientStatus(selectedPatient, "Ready for order activation");
      },
    },
    {
      label: "Order Issues",
      onClick: () => {
        setIsActionModalVisible(false);
        _updatePatientStatus(selectedPatient, "Order Issues");
      },
    },
    {
      label: "Cancelled",
      onClick: () => {
        setIsActionModalVisible(false);
        _updatePatientStatus(selectedPatient, "Cancelled");
      },
    },
  ];

  if (props.activeView === "myRegistraions") {
    return (
      <>
        <ExtendedActions
          isVisible={isActionModalVisible}
          onCancel={(e) => setIsActionModalVisible(false)}
          actions={patientActions}
          modalHeight="540"
          selectedPatient={selectedPatient}
        />
        <div id="labAssistantView">
          <div className="tableActions spaceBetween">
            <SearchInput value={searchValue} onChange={setSearchValue} />
            <div className="usersOptionsWrapper">
              <div className="clinicSelectorWrapper">
                {visitReasons &&
                  visitReasons.length ? (
                    <MaterialSelect
                      options={visitReasons}
                      value={selectedReason}
                      label={"Reason"}
                      onChange={(reason) => setSelectedReason(reason)}
                    />
                  ): <></>}
              </div>
            </div>
          </div>
          <div className="tableInternalScroll">
          <table>
            <thead>
              <tr>
                <th>CN</th>
                <th>Name</th>
                <th>DOB</th>
                <th>FIN</th>
                <th>RV</th>
                <th>Comments</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredRows.map((registration) => (
                <MyRegistrationRow
                  registration={registration}
                  showActionModal={(e) => _showActionModal(registration)}
                  onChange={(patient) =>
                    _updatePatient(registration.PatientID, patient)
                  }
                  onStatusUpdate={(status) =>
                    _updatePatientStatus(registration.PatientID, status)
                  }
                  key={registration.PatientID}
                />
              ))}
            </tbody>
          </table>
          {filteredRows.length === 0 && (
            <div id="noCell">No Registrations Available</div>
          )}
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

export default connect(null, mapDispatchToProps)(MyRegistrations);
