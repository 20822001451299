import React, { Component } from "react";
import Modal from "react-awesome-modal";
import "./extended-action.scss";

const ExtendedActions = (props) => {
  const {
    isVisible = false,
    onCancel = () => {},
    actions = [],
    selectedPatient = null,
    modalHeight = "410",
  } = props;
  return (
    <Modal
      visible={isVisible}
      width="550"
      height={modalHeight}
      effect="fadeInUp"
      onClickAway={onCancel}
    >
      <div id="extendedActionsWrapper">
        <div className="modalHeader">
          <button className="dismissBtn" onClick={onCancel}>
            Dismiss
          </button>
        </div>
        <div className="modalTitleWrapper">
          <p>
            Patient:{" "}
            <span>{selectedPatient &&
            selectedPatient.FirstName &&
            selectedPatient.LastName
              ? selectedPatient.FirstName + " " + selectedPatient.LastName
              : ""}</span>
          </p>
          <p className="callNumber">
          CallNumber:{" "}
            {selectedPatient && selectedPatient.CallNumber
              ? selectedPatient.CallNumber
              : ""}
          </p>
          <p className="actionsLabel">Available Actions</p>
        </div>
        <div className="actionsWrapper">
          {actions.map((action, actionIndex) => (
            <button
              onClick={action.onClick}
              className="modalActionBtn"
              key={actionIndex}
            >
              {action.label}
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ExtendedActions;
