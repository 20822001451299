import React, { useState, useEffect } from "react";
import "./call-number-input.scss";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import InputMask from "react-input-mask";
import Button from "components/CustomButtons/Button.js";
import { updateWizardData } from "../../../../redux/actions/wizard-data";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../../redux/actions/loader-data";
import { checkIfPatientExists } from "../../../../http-calls/index";
import MultiLang from "../../../../multi-lang/index";
import LanguageParsedText from "../../../../multi-lang/language-parser";

const CallNumberInput = (props) => {
  const [callNumber, setCallNumber] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const _updatePhoneNumber = (maskedValue) => {
    let unMaskedCallNumber = maskedValue.replace("(", "");
    unMaskedCallNumber = unMaskedCallNumber.replace(")", "");
    unMaskedCallNumber = unMaskedCallNumber.replace(" ", "");
    unMaskedCallNumber = unMaskedCallNumber.replace("-", "");
    unMaskedCallNumber = unMaskedCallNumber.split("_").join("");
    setCallNumber(unMaskedCallNumber);
  };

  const _parseCallNumber = (patientAddResponse) => {
    const parts = patientAddResponse.split("CallNumber: ");
    return parts[1];
  };

  const _submit = async (e) => {
    e.preventDefault();
    setIsTouched(true);
    _validate();
    // Validate if number is proper
    if (isValid) {
      props.updateWizardData({
        callNumber,
      });
      props.proceedToStep("personalDataInput");
    }
  };

  const _validate = () => {
    if (callNumber.length !== 4) {
      // Not valid
      setIsValid(false);
      return;
    } else {
      // Valid
      setIsValid(true);
    }
  };

  useEffect(() => {
    _validate();
  }, [callNumber]);

  return (
    <div id="phoneInputOuterWrapper">
      <div id="phoneInputInnerWrapper">
        <p>
          <LanguageParsedText value="requiredfields" />
        </p>
        <h3>
        <LanguageParsedText value="callnumberlabel" /> &nbsp; *
        </h3>
        <div className="inputWrapper">
          {/* <input type="number" step="0.01" /> */}
          <InputMask
            {...props}
            mask="(9999)"
            // alwaysShowMask
            value={callNumber}
            onChange={(e) => _updatePhoneNumber(e.target.value)}
            placeholder={"(____)"}
            onBlur={(e) => setIsTouched(true)}
          />
        </div>
        {!isValid && isTouched ? (
          <p className="formError">
            <LanguageParsedText value="callnowarning" />
          </p>
        ) : null}
        <Button color="success" size="lg" onClick={_submit} className="nextBtn">
          <LanguageParsedText value="next" />
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wizardData: state.wizardData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateWizardData: (wizardData) => dispatch(updateWizardData(wizardData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallNumberInput);
