import moment from "moment";
import React, { useState } from "react";

const actions = [
  {
    label: "Select an Action",
    key: null,
  },
  {
    label: "Return to registration desk",
    key: "Return to registration desk",
  },
  {
    label: "Pending Collection",
    key: "Pending Collection",
  },
  {
    label: "Ready for order activation",
    key: "Ready for order activation",
  },
];

const MyRegistrationRow = (props) => {
  const { registration, onChange, onStatusUpdate } = props;
  const [fin, setFin] = useState("");
  const _updateComment = (comment) => {
    onChange({
      ...registration,
      internalcomments: comment,
    });
  };

  const _updateStatus = (status) => {
    props.onStatusUpdate(status);
  };

  const _updateFin = (fin) => {
    onChange({
      ...registration,
      FIN: fin,
    });
  };

  return (
    <tr>
      <td data-column="Call Number">{registration.CallNumber}</td>
      <td data-column="Name" className="nameCell">
        <p>{registration.FirstName + " " + registration.LastName}</p>
      </td>
      <td data-column="DOB">
        {moment(registration.DOB).format(process.env.REACT_APP_DATE_FORMAT)}
      </td>
      <td data-column="FIN" className="finCell">
        <input
          placeholder="Enter FIN No"
          value={registration.FIN}
          onChange={(e) => _updateFin(e.target.value)}
        />
      </td>
      <td data-column="RV">
        {registration.VisitType ? registration.VisitType : "--"}
      </td>
      <td data-column="Comments">
        <textarea
          className="customInput"
          value={registration.internalcomments}
          onChange={(e) => _updateComment(e.target.value)}
        />
      </td>
      <td data-column="Status">
        <div className="buttonsWrapper">
          <button className="actions" onClick={props.showActionModal}>
            <i className="fa fa-cog" aria-hidden="true"></i>
          </button>
          {/* <button className="messages">
            <div className="unreadNotification"></div>
            <i className="fa fa-envelope-open" aria-hidden="true"></i>
          </button> */}
        </div>
      </td>
    </tr>
  );
};

export default MyRegistrationRow;
