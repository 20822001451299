import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from "components/CustomButtons/Button.js";
import Numpad from "modules/general/components/numpad/numpad";
import moment from "moment";
import LanguageParsedText from "multi-lang/language-parser";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import { updateWizardData } from "../../../../redux/actions/wizard-data";
import "./personal-data-input.scss";

const PersonalDataInput = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formError, setFormError] = useState("requiredwarning");

  const _submit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    _validate();
    if (
      firstName.length &&
      lastName.length &&
      dateOfBirth &&
      dateOfBirth.length &&
      isDateValid(dateOfBirth)
    ) {
      const personalData = {
        firstName,
        lastName,
      };
      if (dateOfBirth) {
        personalData.dob = _maskDate(dateOfBirth);
      }
      props.updateWizardData({
        ...personalData,
      });
      props.proceedToStep("appointmentTypeSelection");
    } else {
      setIsFormValid(false);
    }
  };

  const _maskDate = value => {
    let v = value.replace(/\D/g,'').slice(0, 10);
    if (v.length >= 5) {
      return `${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4)}`;
    }
    else if (v.length >= 3) {
      return `${v.slice(0,2)}/${v.slice(2)}`;
    }
    return v
  }

  const isDateValid = (dateOfBirth) => {
    let unMaskedDob = dateOfBirth.replace(/_/g, "");
    unMaskedDob = unMaskedDob.replace(/\//g, "");
    if (unMaskedDob.length !== 8) {
      return false;
    }
    if (!moment(dateOfBirth, "MM/DD/YYYY").isValid()) {
      return false;
    }
    if (!moment(unMaskedDob, "MM/DD/YYYY").isBefore(moment())) {
      return false;
    }
    if (
      !moment(unMaskedDob, "MM/DD/YYYY").isAfter(
        moment("31/12/1918", "DD/MM/YYYY")
      )
    ) {
      return false;
    }
    return true;
  };

  const _validate = () => {
    if (
      firstName.length &&
      lastName.length &&
      dateOfBirth &&
      dateOfBirth.length
    ) {
      if (isDateValid(dateOfBirth)) {
        setIsFormValid(true);
      } else {
        setFormError("dobwarning");
        setIsFormValid(false);
      }
    } else {
      setIsFormValid(false);
      setFormError("requiredwarning");
    }
  };

  const _appendCharToDOB = (number) => {
    let _dateOfBirth = dateOfBirth;
    if (dateOfBirth.length < 8) {
      _dateOfBirth += `${number}`;
      setDateOfBirth(_dateOfBirth);
    }
  };

  const _performDeleteCharOnDOB = () => {
    let _dateOfBirth = dateOfBirth.slice(0, -1);
    setDateOfBirth(_dateOfBirth);
  };

  useEffect(() => {
    _validate();
  }, [firstName, lastName]);

  useEffect(() => {
    if (props.wizardData) {
      const { firstName, lastName, dob } = props.wizardData;
      setFirstName(firstName);
      setLastName(lastName);
      if (dob && dob.length) {
        setDateOfBirth(dob);
      }
    }
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div id="personalDataInputWrapper">
        <div id="personalData">
          <p>
            <LanguageParsedText value="requiredfields" />
          </p>
          <CustomInput
            labelText={
              <>
                <LanguageParsedText value="lastname" />{" "}
                <span className="requried">*</span>
              </>
            }
            id="float"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              className: "userInput",
              value: lastName,
              onChange: (e) => setLastName(e.target.value),
            }}
          />
          <CustomInput
            labelText={
              <>
                <LanguageParsedText value="firstname" />{" "}
                <span className="requried">*</span>
              </>
            }
            id="float"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: firstName,
              onChange: (e) => setFirstName(e.target.value),
            }}
          />
          <div className="datePickerWrapper">
            <p>
              <LanguageParsedText value="dob" />
              <span className="requried">*</span>
            </p>
            <InputMask
              {...props}
              mask="99/99/9999"
              // alwaysShowMask
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              placeholder={"MM/DD/YYYY"}
              // onBlur={(e) => {
              //   setIsSubmitting(true);
              // }}
            />
          </div>
          {!isFormValid && isSubmitting ? (
            <p className="formError">
              <LanguageParsedText value={formError} />
            </p>
          ) : null}
          <Button
            color="success"
            size="lg"
            onClick={_submit}
            className="nextBtn"
          >
            <LanguageParsedText value="next" />
          </Button>
        </div>
        <Numpad
          onDelete={(e) => _performDeleteCharOnDOB()}
          onKeyPress={(number) => _appendCharToDOB(number)}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    wizardData: state.wizardData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateWizardData: (wizardData) => dispatch(updateWizardData(wizardData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDataInput);
