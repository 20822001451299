import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import { updateWizardData } from "../../../../redux/actions/wizard-data";
import "./registration-success.scss";
import LanguageParsedText from "../../../../multi-lang/language-parser";
import QRCode from "react-qr-code";
import Progress from "react-progressbar";

let timerInterval = null;
let seconds = 0;
const RegistrationSuccess = (props) => {
  const [waitSeconds, setWaitSeconds] = useState(0);

  useEffect(() => {
    timerInterval = setInterval(() => {
      seconds++;
      setWaitSeconds(seconds);
      if (seconds === parseInt(process.env.REACT_APP_SUCCESS_VIEW_WAIT_TIME)) {
        _reset();
      }
    }, 1000);
  }, []);

  const _reset = () => {
    clearInterval(timerInterval);
    setWaitSeconds(0);
    props.onRestart();
  };

  const _getPercentage = () => {
    const percentage =
      100 *
      (1 -
        waitSeconds / parseInt(process.env.REACT_APP_SUCCESS_VIEW_WAIT_TIME));
    // Always return positive value, the plugin doesn't support negative values
    if (percentage >= 0) {
      return percentage;
    } else {
      return 0;
    }
  };

  const { callNo = "123456", isPreviousRegistration = false } = props;
  const qrLink = `${window.location.origin}/patient-status?id=${props.patientVisitId}`;
  const shouldShowQr = props.visitSettings.find(s => s.PatientVisitOption === "QRCode Visit Status");
  return (
    <div id="registrationSuccess">
      {props.patientVisitId && shouldShowQr ? (
        <div className="qrCode">
          <QRCode value={qrLink} size={128} />
          <p>Scan the code to check your status</p>
        </div>
      ) : (
        <></>
      )}
      <h3>
        <LanguageParsedText value="callnumberdisplaylabel" />{" "}
        <span>{callNo}</span>.<br />
        <LanguageParsedText value="waittext" />
      </h3>
      <button className="resetBtn" onClick={_reset}>
        New Registration
      </button>
      <div className="progressWrapper">
        <Progress completed={_getPercentage()} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wizardData: state.wizardData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateWizardData: (wizardData) => dispatch(updateWizardData(wizardData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationSuccess);
