import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ClinicManagementModule from "./clinic-management-module";
import LabAssistantModule from "./lab-assistant-module/index";
import PhlebotomistModule from "./phlebotomist-module/index";
import RegistrationModule from "./registration-module/index";
import TvDisplayModule from "./tv-display";
import UserActivation from "./user-activation/index";
import UserManagementModule from "./user-management-module";
import VisitSystemsSettingsModule from "./visit-system-settings-module";
import DashboardModule from "./dashboard-module";
import StaticPage from "./general/pages/static-page/static-page";
import PatientStatusModule from "./patient-status-module";
import FaqModule from "./faq-module";

class Modules extends Component {
  state = {};
  render() {
    return (
      <>
        <Switch>
          <Route path="/" component={TvDisplayModule} />
          <Route path="/test" component={StaticPage} />
          <Route path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </>
    );
  }
}

export default Modules;
