import React, { useState, useEffect } from "react";
import UserSelectionView from "./components/user-selection-view/user-selection-view";
import Header from "./containers/header/header";
import PatientTable from "./components/patient-table/patient-table";
import { extractQueryParams } from "helper-methods";

const PhlebotomistModule = (props) => {
  const [currentView, setCurrentView] = useState("users");
  const [selectedUser, setSelectedUser] = useState(null);
  const [clinicId, setClinicId] = useState("-1");

  const _extractClinicId = () => {
    const params = extractQueryParams();
    const clinicId = Object.keys(params).find(param => param.toLocaleLowerCase() === 'clinicid');
    if (clinicId) {
      setClinicId(params[clinicId]);
    }
  };

  const _logout = () => {
    setCurrentView("users");
    setSelectedUser(null);
  };

  useEffect(() => {
    _extractClinicId();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setCurrentView("table");
    }
  }, [selectedUser]);

  return (
    <>
      <Header logout={_logout} selectedUser={selectedUser} />
      {clinicId > -1 ? (
        <>
          {currentView === "users" ? (
            <UserSelectionView
              selectUser={setSelectedUser}
              clinicId={clinicId}
            />
          ) : (
            <PatientTable selectedUser={selectedUser} clinicId={clinicId} logout={_logout} />
          )}
        </>
      ) : (
        <div className="clinicIdError" style={{width: "100vw", height: "100vh"}}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p>
            This seems to be a configuration error. <br /> Please notify the
            front desk.
          </p>
        </div>
      )}
    </>
  );
};

export default PhlebotomistModule;
