import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Select from "@material-ui/core/Select";
import Button from "components/CustomButtons/Button.js";
import React, { useState, useEffect } from "react";
import "./appointment-type-selector.scss";
import { makeStyles } from "@material-ui/core/styles";

import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import { connect } from "react-redux";
import { updateWizardData } from "../../../../redux/actions/wizard-data";
import LanguageParsedText from "multi-lang/language-parser";
import { submitPatientData } from "http-calls";
import { showLoader, hideLoader } from "../../../../redux/actions/loader-data";
import { showToast } from "../../../../helper-methods/index";

const useStyles = makeStyles(basicsStyle);

const AppointmentTypeSelector = (props) => {
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const _formatPatientData = () => {
    const { wizardData } = props;
    const formattedPatientData = {
      FirstName: wizardData.firstName,
      LastName: wizardData.lastName,
      MobileNumber: wizardData.phoneNumber,
      DOB: wizardData.dob,
      VisitType: simpleSelect,
      ClinicID: props.clinicId,
      Language: props.languageData.currentLang
    };
    if (wizardData.phoneNumber && wizardData.phoneNumber.length) {
      formattedPatientData["MobileNumber"] = wizardData.phoneNumber;
    } else {
      formattedPatientData["callnumber"] = wizardData.callNumber;
      formattedPatientData["MobileNumber"] = wizardData.callNumber;
    }
    return formattedPatientData;
  };

  const _parseCallNumber = (stringifiedResponse) => {
    try {
      const responseObject = JSON.parse(stringifiedResponse);
      if (responseObject.CallNumber) {
        return responseObject.CallNumber.toString();
      } else {
        throw new Error();
      }
    } catch (error) {
      return "No call number";
    }
  };

  const _parsePatientVisitId = (stringifiedResponse) => {
    try {
      const responseObject = JSON.parse(stringifiedResponse);
      if (responseObject.PatientVisitID) {
        return responseObject.PatientVisitID.toString();
      } else {
        throw new Error();
      }
    } catch (error) {
      return "";
    }
  };

  const _selectOption = (option) => {
    setSimpleSelect(option);
  };

  const _submitWithOutReason = async () => {
    setIsSubmitting(true);
    const personalData = {
      visitReason: simpleSelect,
    };
    props.updateWizardData({
      ...personalData,
    });
    // Submit final data
    try {
      const { wizardData } = props;
      const formattedPatientData = {
        FirstName: wizardData.firstName,
        LastName: wizardData.lastName,
        MobileNumber: wizardData.phoneNumber,
        DOB: wizardData.dob,
        ClinicID: props.clinicId,
        Language: props.languageData.currentLang
      };
      if (wizardData.phoneNumber && wizardData.phoneNumber.length) {
        formattedPatientData["MobileNumber"] = wizardData.phoneNumber;
      } else {
        formattedPatientData["callnumber"] = wizardData.callNumber;
        formattedPatientData["MobileNumber"] = wizardData.callNumber;
      }
      props.showLoader(" ");
      const patientAddResponse = await submitPatientData(formattedPatientData);
      const callNumber = _parseCallNumber(patientAddResponse);
      const patientVisitId = _parsePatientVisitId(patientAddResponse);
      props.hideLoader();
      if (callNumber && callNumber.length) {
        props.showRegistrationSucessPage(callNumber, false, patientVisitId);
      } else {
        showToast(patientAddResponse);
      }
    } catch (error) {
      showToast("Server error, please try again");
      props.hideLoader();
    }
  };

  const _submit = async () => {
    setIsSubmitting(true);
    const personalData = {
      visitReason: simpleSelect,
    };
    props.updateWizardData({
      ...personalData,
    });
    // Submit final data
    try {
      const patient = _formatPatientData();
      props.showLoader(" ");
      const patientAddResponse = await submitPatientData(patient);
      const callNumber = _parseCallNumber(patientAddResponse);
      const patientVisitId = _parsePatientVisitId(patientAddResponse);
      props.hideLoader();
      if (callNumber && callNumber.length) {
        props.showRegistrationSucessPage(callNumber, false, patientVisitId);
      } else {
        showToast(patientAddResponse);
      }
    } catch (error) {
      showToast("Server error, please try again");
      props.hideLoader();
    }
  };

  useEffect(() => {
    if (simpleSelect && simpleSelect.length) {
      _submit();
    }
  }, [simpleSelect]);

  useEffect(() => {
    if (!(props.visitReasons && props.visitReasons.length)) {
      _submitWithOutReason();
    }
  }, [props.visitReasons]);

  const classes = useStyles();
  const { visitReasons } = props;

  return (
    <div id="appointmentSelectorWrapper">
      <div id="personalData">
        <div className="typeOptionsWrapper">
          <div className="titleWrapper">
            <LanguageParsedText value="visitReason" />{" "}
            <span className="requried">*</span>
          </div>
          <GridContainer className="visitReasonWrapperCheckin">
            {visitReasons.map((option, optionIndex) => (
              <GridItem xs={12} sm={6} md={6} lg={6} key={optionIndex}>
                <Button
                  size="lg"
                  className={"languageSelector"}
                  onClick={(e) => _selectOption(option)}
                >
                  {option}
                </Button>
              </GridItem>
            ))}
          </GridContainer>
        </div>
        {!isFormValid && isSubmitting ? (
          <p className="formError">
            <LanguageParsedText value="requiredwarning" />
          </p>
        ) : null}
        <Button
          color="warning"
          size="lg"
          onClick={() => props.proceedToStep("personalDataInput")}
          className="prevBtn"
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i> &nbsp;
          &nbsp;
          <LanguageParsedText value="previous" />
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wizardData: state.wizardData,
    languageData: state.languageData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateWizardData: (wizardData) => dispatch(updateWizardData(wizardData)),
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentTypeSelector);
