import { deepClone, sleepTime } from "helper-methods";
import {
  getMyPhlebotomistPatients,
  getPhlebotomistPatientsInQueue,
  updatePatientStatus,
} from "http-calls";
import SearchInput from "modules/general/components/search-input/search-input";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import "./patient-table.scss";

let intervalRef = null;

const PatientTable = (props) => {
  const [patientsInQueue, setPatientsInQueue] = useState([]);
  const [filteredRowsForMyPatients, setFilteredRowsForMyPatients] = useState(
    []
  );
  const [
    filteredRowsForOtherPatients,
    setFilteredRowsForOtherPatients,
  ] = useState([]);
  const [
    searchValueForOtherPatients,
    setSearchValueForOtherPatients,
  ] = useState("");
  const [searchValueForMyPatients, setSearchValueForMyPatients] = useState("");
  const [myPatients, setMyPatients] = useState([]);

  const _loadPatients = async () => {
    _initiateBackgroundRefresher();
    try {
      const myPatientsResponse = await getMyPhlebotomistPatients(
        props.selectedUser.Username,
        props.clinicId
      );
      setMyPatients(myPatientsResponse);
    } catch (error) {
      console.log("error :>> ", error);
      setMyPatients([]);
      // showToast("Error while assigning");
      props.hideLoader();
    }
    try {
      const patientsInQueueResponse = await getPhlebotomistPatientsInQueue(
        props.clinicId
      );
      setPatientsInQueue(patientsInQueueResponse);
      props.hideLoader();
    } catch (error) {
      console.log("error :>> ", error);
      // showToast("Error while assigning");
      setPatientsInQueue([]);
      await sleepTime(700);
      props.hideLoader();
    }
  };

  const _filterRowsForMyPatients = () => {
    let filteredRows = deepClone(myPatients);
    const lowerCasedSearchValue = searchValueForMyPatients.toLowerCase().trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredRows = filteredRows.filter((row) => {
        if (row.CallNumber.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if (
          `${row.FirstName} ${row.LastName}`
            .toLowerCase()
            .indexOf(lowerCasedSearchValue) > -1
        ) {
          return true;
        }
      });
    }
    setFilteredRowsForMyPatients(filteredRows);
  };

  const _filterRowsForOtherPatients = () => {
    let filteredRows = deepClone(patientsInQueue);
    const lowerCasedSearchValue = searchValueForOtherPatients
      .toLowerCase()
      .trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredRows = filteredRows.filter((row) => {
        if (row.CallNumber.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if (
          `${row.FirstName} ${row.LastName}`
            .toLowerCase()
            .indexOf(lowerCasedSearchValue) > -1
        ) {
          return true;
        }
      });
    }
    setFilteredRowsForOtherPatients(filteredRows);
  };

  const _addToMyself = async (patient) => {
    try {
      props.showBottomLoader("Calling");
      await updatePatientStatus({
        ...patient,
        PatientStatusTypeID: 5,
        UpdatedBy: props.selectedUser.Username,
      });
      await sleepTime(500);
      props.logout();
    } catch (error) {
      props.hideLoader();
    }
  };

  const _markPatientAsComplete = async (patient) => {
    try {
      props.showBottomLoader("Marking as complete");
      await updatePatientStatus({
        ...patient,
        PatientStatusTypeID: 6,
        UpdatedBy: props.selectedUser.Username,
      });
      props.hideLoader();
      await sleepTime(500);
      props.logout();
    } catch (error) {
      props.hideLoader();
    }
  };

  const _markPatientAsNoAnswer = async (patient) => {
    try {
      props.showBottomLoader("Calling");
      await updatePatientStatus({
        ...patient,
        PatientStatusTypeID: 8,
        UpdatedBy: props.selectedUser.Username,
      });
      props.hideLoader();
      await sleepTime(500);
      props.logout();
    } catch (error) {
      props.hideLoader();
    }
  };

  const _initiateBackgroundRefresher = () => {
    if (!intervalRef) {
      intervalRef = setInterval(() => {
        _loadPatients();
      }, 5000);
    }
  };

  useEffect(() => {
    props.showLoader("Loading");
    _loadPatients();
    return () => {
      clearInterval(intervalRef);
    };
  }, []);

  useEffect(() => {
    _filterRowsForMyPatients();
  }, [myPatients]);

  useEffect(() => {
    _filterRowsForMyPatients();
  }, [searchValueForOtherPatients]);

  useEffect(() => {
    _filterRowsForOtherPatients();
  }, [patientsInQueue]);

  useEffect(() => {
    _filterRowsForOtherPatients();
  }, [searchValueForOtherPatients]);

  if (myPatients.length) {
    return (
      <div className="tableWrapper">
        <h3>My Patients</h3>
        <div id="table" className="pTable">
          <div className="tableActions centered">
            <SearchInput
              value={searchValueForMyPatients}
              onChange={setSearchValueForMyPatients}
            />
          </div>
          <div className="tableInternalScroll">
          <table>
            <thead>
              <tr>
                <th>Call Number</th>
                <th>Name</th>
                <th>Reason for Visit</th>
                <th>Comment</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredRowsForMyPatients.map((patient, patientIndex) => (
                <tr key={patientIndex}>
                  <td>{patient.CallNumber}</td>
                  <td>{patient.FirstName + " " + patient.LastName}</td>
                  <td>{patient.VisitType.toUpperCase()}</td>
                  <td>{patient.internalcomments}</td>
                  <td>
                    <div className="actions">
                      <button
                        onClick={(e) => _markPatientAsComplete(patient)}
                        className="completeBtn"
                      >
                        Complete
                      </button>
                      <button
                        onClick={(e) => _markPatientAsNoAnswer(patient)}
                        className="noAnswerBtn"
                      >
                        No Answer
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {myPatients.length === 0 && (
            <div id="noCell">No Patients Available</div>
          )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="tableWrapper">
        <h3>Available Patients</h3>
        <div id="table" className="pTable">
          <div className="tableActions centered">
            <SearchInput
              value={searchValueForOtherPatients}
              onChange={setSearchValueForOtherPatients}
            />
          </div>
          <div className="tableInternalScroll">
          <table>
            <thead>
              <tr>
                <th>Call Number</th>
                <th>Name</th>
                <th>Reason for Visit</th>
                <th>Comment</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredRowsForOtherPatients.map((patient, patientIndex) => (
                <tr key={patientIndex}>
                  <td>{patient.CallNumber}</td>
                  <td>{patient.FirstName + " " + patient.LastName}</td>
                  <td>{patient.VisitType.toUpperCase()}</td>
                  <td>{patient.internalcomments}</td>
                  <td>
                    <div className="actions">
                      <button
                        className="call"
                        onClick={(e) => _addToMyself(patient)}
                      >
                        Call
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {patientsInQueue.length === 0 && (
            <div id="noCell">No Patients Available</div>
          )}
          </div>
        </div>
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

export default connect(null, mapDispatchToProps)(PatientTable);
